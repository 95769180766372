export function gaUser(userId: any) {
  gaEvent({
    action: "user-id available",
    category: "authentication",
    label: userId,
  });
  window.gtag("config", GA_TRACKING_ID, {
    user_id: userId,
  });
}

export const GA_TRACKING_ID = "G-4LM0JEPSS9"; // "UA-1683666-1"; //

export const pageview = (url) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

export const gaEvent = ({ action, category, label = null }) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
  });
};
