import { api } from "../services/api";
import { setLoadingBar } from "./siteData";
import { LobbyRoom } from "@chesshotel/constants";
import { setUser, serverLoggedIn, resetUser, userStore } from "./user";
import { setSettings } from "./settings";
import { initWebsocketConnect } from "../services/websocket";
import { loginGuest } from "./user";
import Router from "next/router";
import { GA_TRACKING_ID, gaUser } from "../lib/ga";
import { generateId } from "../util/generateId";
import { createReduxModule } from "hooks-for-redux";
import Cookies from "universal-cookie";
import { setMembership } from "./membership";
import { growthbook } from "../util/growthbook";
import { loadFacebook, signOutFB } from "../services/facebook";
import { setGuestFilterTime } from "./lobby";

const initialState = {
  loading: true,
  fbNeedsUpgrade: false,
  fbUpgradeHasPass: false,
};

export const [
  useSignIn,
  { setLoading, setFbNeedsUpgrade, setFbUpgradeClose },
  signInStore,
] = createReduxModule("signIn", initialState, {
  setLoading: (state, loading) => ({
    ...state,
    loading,
  }),
  setFbNeedsUpgrade: (state, { fbNeedsUpgrade, fbUpgradeHasPass }) => ({
    ...state,
    fbNeedsUpgrade,
    fbUpgradeHasPass,
  }),
  setFbUpgradeClose: (state) => ({
    ...state,
    fbNeedsUpgrade: false,
    fbUpgradeHasPass: false,
  }),
});

// function setUserAndMembership(json) {
//   const isGuest = false;
//   const room = LobbyRoom.MEMBERS;
//   const user = json.user;

//   if (user.fbNeedsUpgrade) {
//     const { fbNeedsUpgrade, fbUpgradeHasPass } = user;
//     setFbNeedsUpgrade({ fbNeedsUpgrade, fbUpgradeHasPass });
//     delete user.fbNeedsUpgrade;
//     delete user.fbUpgradeHasPass;
//     loadFacebook();
//   }

//   setUser({ ...user, isGuest, room });
//   setMembership(json.membership);
// }

export async function setUserData(json) {
  const username = json.user.username;
  const userId = json.user.id;
  const isGuest = false;
  const room = LobbyRoom.MEMBERS;
  const user = json.user;
  const leagueGames = json.membership?.leagueGames;

  setUser({ ...user, isGuest, room });
  setMembership(json.membership);

  const [stats, leagues, lobby, friends, daily, challenges, notifications] =
    await Promise.all([
      import("./stats"),
      import("./leagues"),
      import("./lobby"),
      import("./friends"),
      // import("./membership"),
      import("./daily"),
      import("./challenges"),
      import("./notifications"),
    ]);

  stats.setStats(json.stats);
  leagues.setLeagueScores(
    json.leagueScores,
    json.divisionScores,
    json.lastSeason,
    json.seasonId
  );

  setSettings(json.settings);

  lobby.setLeaguePlay(leagueGames > 0);

  friends.setFriends(json.friends);
  friends.setFriendRequests(json.friendRequests);

  daily.setDailyInvites(json.dailyInvites);
  daily.doSetDailyGames(json.dailyGames);
  challenges.setChallenges(json.privateChallenges);

  notifications.setNotifications(json.notifications);

  if (user.fbNeedsUpgrade) {
    const { fbNeedsUpgrade, fbUpgradeHasPass } = user;
    setFbNeedsUpgrade({ fbNeedsUpgrade, fbUpgradeHasPass });
    delete user.fbNeedsUpgrade;
    delete user.fbUpgradeHasPass;
    loadFacebook();
  }

  initWebsocketConnect();
  gaUser(userId);
}

// export function doSetUser(json) {
//   setUserAndMembership(json);
//   setUserData(json);
// }

function getIsTestHours() {
  const hourOfDay = new Date().getUTCHours();
  return hourOfDay > 9 && hourOfDay < 23;
}

function setGuestFilter() {
  if (getIsTestHours()) {
    setGuestFilterTime(true);
    const intervalId = setInterval(() => {
      if (!getIsTestHours()) {
        setGuestFilterTime(false);
        clearInterval(intervalId);
      }
    }, 360000);
  }
}

export function loginComplete() {
  serverLoggedIn(true);
  setGuestFilter();
}

function autoLoginGuest() {
  const { isGuest, displayName } = userStore.getState();
  if (isGuest) {
    loginGuest(displayName);
  } else {
    const defaultGuestName = "Guest" + generateId();
    loginGuest(defaultGuestName);
  }
}

export function signInStatus() {
  const lang = Router.locale;

  api
    .get("/auth/status/" + lang)
    .then((response) => {
      let loggedIn = false;
      if (response.data) {
        setLoading(false);
        setUserData(response.data);
        loggedIn = true;
      } else {
        autoLoginGuest();
        setLoading(false);
      }

      window.gtag("get", GA_TRACKING_ID, "client_id", (client_id) => {
        growthbook.setAttributes({
          anonimous_id: client_id,
          loggedIn,
          lang,
        });
      });
    })
    .catch((e) => {
      console.error("signInStatus fail", e);
      autoLoginGuest();
      setLoading(false);
    });
}

/* SIGN OUT */
export async function signOut() {
  const user = userStore.getState();
  Router.replace("/");
  if (user.fbId) {
    loadFacebook();
    signOutFB();
  }
  resetUser();

  const [stats, leagues, lobby, gamePlay, membership, settings, daily] =
    await Promise.all([
      import("./stats"),
      import("./leagues"),
      import("./lobby"),
      import("./gamePlay"),
      import("./membership"),
      import("./settings"),
      import("./daily"),
    ]);

  stats.resetStats();
  settings.reset();
  membership.resetMembership();
  leagues.resetLeagueScores();
  lobby.reset();
  gamePlay.resetGamePlay();
  daily.reset();
  const cookies = new Cookies();
  cookies.remove("browserId", { path: "/", domain: ".chesshotel.com" });
  autoLoginGuest();
  api.get("/auth/signout");
}

export function recoverPassword(password, userId) {
  setLoadingBar(true);
  api
    .post("/password/recover", { password, userId })
    .then(() => {
      Router.push("/signIn/passwordRecoveryDone");
    })
    .then(() => {
      setLoadingBar(false);
    });
}
