// import { gaEvent } from "./lib/ga";
// import { userStore } from "./ducks/user";

// export function trackPwaInstalls() {
//   window.addEventListener("appinstalled", () => {
//     // If visible, hide the install promotion

//     const userId = userStore.getState().id;
//     gaEvent({
//       action: "pwaInstall",
//       category: "pwa",
//       label: userId,
//     });
//   });
// }

export function removeServiceWorker() {
  navigator.serviceWorker?.getRegistrations().then((registrations) => {
    if (document.cookie.includes("webpushSubscribed=true")) {
      console.log("Skipping service worker removal for subscribed user.");
      return;
    }

    const unregisterPromises = registrations.map((registration) =>
      registration.unregister()
    );
    Promise.all(unregisterPromises)
      .then(() => {
        console.log(
          "All service workers unregistered. Proceeding to redirect..."
        );
        redirectUser(); // Call your redirection logic here
      })
      .catch((error) =>
        console.error("Error during service worker unregistration:", error)
      );
  });
}

function doConfirm() {
  return confirm(
    "The PWA version is no longer supported. Would you like to download our mobile app for the best experience?"
  );
}

function redirectUser() {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    // Detect the user's platform
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store
      const proceed = doConfirm();

      if (!proceed) return;
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.foggymedia.ChessHotel";
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      const proceed = doConfirm();

      if (!proceed) return;
      // Redirect to Apple App Store
      window.location.href = "https://appstore.com/chesshotel";
    } else {
      // Default fallback (e.g., link to your website or a general app landing page)
      window.location.href = "https://www.chesshotel.com";
    }
  }
}
