import React, { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import styles from "./Navigation.module.css";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { FaCog } from "@react-icons/all-files/fa/FaCog";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";
import { FaBell } from "@react-icons/all-files/fa/FaBell";
import { FaBars } from "@react-icons/all-files/fa/FaBars";
import { FaUsers } from "@react-icons/all-files/fa/FaUsers";
import { Friend } from "@chesshotel/types";
import UserAvatar from "../modules/UserAvatar";
import { useRouter } from "next/router";
import Link from "next/link";
import Head from "next/head";
import dynamic from "next/dynamic";
import { signOut, useSignIn } from "../ducks/signIn";
import { useUser } from "../ducks/user";
import { useSiteData } from "../ducks/siteData";
import { useFriends } from "../ducks/friends";
import { useFriendChat } from "../ducks/friendChat";
import { useChallenges, setChallengesMenuOpen } from "../ducks/challenges";
import MobileMenuDrawer from "./MobileMenuDrawer";
import { useWsConnection } from "../ducks/wsConnection";
import ChallengesMenu from "./ChallengesMenu";
import { RiSwordFill } from "@react-icons/all-files/ri/RiSwordFill";
import { FaTrophy } from "@react-icons/all-files/fa/FaTrophy";
import { useNotifications } from "../ducks/notifications";
import { useGamePlay } from "../ducks/gamePlay";
import { FaExclamationCircle } from "@react-icons/all-files/fa/FaExclamationCircle";
const NotificationsMenu = dynamic(() => import("./NotificationsMenu"));
const FriendChat = dynamic(() => import("../modules/friendChat/FriendChat"));
const OtherLoginPopup = dynamic(() => import("../modules/OtherLoginPopup"));

const SHOW_BLOG = false;

function NavLink({
  href,
  exact,
  activeClassName,
  children,
  prefetch = true,
  ...props
}) {
  const { pathname } = useRouter();
  const isActive = exact ? pathname === href : pathname.startsWith(href);

  if (isActive) {
    props.className += " " + activeClassName;
  }

  return (
    <Link {...props} href={href} prefetch={prefetch}>
      {children}
    </Link>
  );
}

export default function Navigation() {
  const { t } = useTranslation("common");

  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [notificationsMenuOpen, setNotificationsMenuOpen] = useState(false);

  const { username, memberLoggedIn } = useUser();
  const { loading } = useSignIn();

  const { loadingBarVisible, gamePath } = useSiteData();

  const { gameEnded, gameId, gameIdSeen } = useGamePlay();

  const { friendList, friendRequests } = useFriends();
  const { unreadMessages, visible } = useFriendChat();

  const { challenges, challengesMenuOpen } = useChallenges();
  const { list } = useNotifications();

  const { otherLoginShow } = useWsConnection();

  const router = useRouter();

  const currentPath = router.pathname;
  const isShortNav = currentPath != "/"; //currentPath === gamePath || currentPath === "/analysis";
  const isGamePath = currentPath === gamePath;
  let unreadCount = 0;
  let badgeCount = 0;
  const challengeCount = challenges.length;
  let friendsOnline = 0;

  function checkFriends() {
    for (let key in unreadMessages) {
      unreadCount += unreadMessages[key];
    }

    badgeCount =
      currentPath !== "/friends" && friendRequests
        ? friendRequests.length + unreadCount
        : 0;

    friendList.forEach((friend: Friend) => {
      if (friend.online) {
        friendsOnline++;
      }
    });
  }

  if (memberLoggedIn) {
    checkFriends();
  }

  function renderUserMenu() {
    return (
      <div className={styles.userMenu}>
        <Link
          onClick={() => setUserMenuActive(false)}
          className={styles.userMenuItem}
          href={"/profile"}
        >
          <div className={styles.icon}>
            <FaUser />
          </div>
          {t("Profile")}
        </Link>
        <Link
          onClick={() => setUserMenuActive(false)}
          className={styles.userMenuItem}
          href={"/leagues"}
        >
          <div className={styles.icon}>
            <FaTrophy />
          </div>
          {t("Leagues")}
        </Link>
        <Link
          onClick={() => setUserMenuActive(false)}
          className={styles.userMenuItem}
          href={"/settings/0"}
        >
          <div className={styles.icon}>
            <FaCog />
          </div>
          {t("Settings")}
        </Link>
        <a
          className={styles.userMenuItem}
          onClick={() => {
            setUserMenuActive(false);
            signOut();
          }}
        >
          <div className={styles.icon}>
            <FaSignOutAlt />
          </div>
          {t("Sign out")}
        </a>
      </div>
    );
  }

  function renderMemberMenu() {
    function hideChallengeMenu(e) {
      e.stopPropagation();
      setChallengesMenuOpen(false);
    }

    function hideNotificationsMenu(e) {
      e.stopPropagation();
      setNotificationsMenuOpen(false);
    }

    return (
      <>
        {userMenuActive && (
          <div
            onClick={() => setUserMenuActive(false)}
            className={styles.menuBg}
          ></div>
        )}
        <div className={"select-none md:top-0"}>
          {userMenuActive && renderUserMenu()}
          <div className="flex items-center space-x-3 md:space-x-4">
            <div className="relative flex flex-col items-center">
              {list.length > 0 ? (
                <NotificationsMenu />
              ) : (
                <button
                  className="rounded-full focus:outline-none"
                  aria-label="Notifications"
                  onClick={() => setNotificationsMenuOpen((prev) => !prev)}
                >
                  <div className={`flex relative items-center space-x-2`}>
                    <span className="cursor-pointer has-tooltip">
                      <span className="tooltip">{t("Notifications")}</span>
                      <div className="relative flex items-center justify-center text-xs text-gray-600 bg-gray-200 rounded-full w-9 h-9 hover:bg-gray-300 active:opacity-60">
                        <div className="text-lg">
                          <FaBell />
                        </div>
                      </div>
                    </span>
                  </div>
                </button>
              )}
              {notificationsMenuOpen && (
                <>
                  <div className="absolute z-30 p-4 text-sm translate-y-full bg-white border rounded-lg shadow -bottom-2 whitespace-nowrap border-slate-100">
                    {t("No new notifications")}
                  </div>
                  <div
                    className="hidden-overlay"
                    onMouseUp={(e) => hideNotificationsMenu(e)}
                  />
                </>
              )}
            </div>

            <div
              className={`flex relative flex-col items-center`}
              onClick={() => setChallengesMenuOpen(!challengesMenuOpen)}
            >
              <div className="relative">
                <span className="cursor-pointer has-tooltip">
                  <span className="tooltip">{t("Challenges")}</span>
                  <div className="relative flex items-center justify-center text-xs text-gray-600 bg-gray-200 rounded-full w-9 h-9 hover:bg-gray-300 active:opacity-60">
                    <div className="text-lg">
                      <RiSwordFill />
                    </div>

                    {challengeCount > 0 && (
                      <div className="badge">{challengeCount}</div>
                    )}
                  </div>
                </span>
              </div>
              {challengesMenuOpen && (
                <>
                  <ChallengesMenu />
                  <div
                    className="hidden-overlay"
                    onMouseUp={(e) => hideChallengeMenu(e)}
                  />
                </>
              )}
            </div>

            <span className="cursor-pointer has-tooltip">
              <span className="tooltip">{t("Friends")}</span>
              <div
                onClick={() => router.push("/friends")}
                className="relative flex items-center justify-center text-xs text-gray-600 bg-gray-200 rounded-full w-9 h-9 hover:bg-gray-300 active:opacity-60"
              >
                <div className="text-lg">
                  <FaUsers />
                </div>
                {badgeCount > 0 && <div className="badge">{badgeCount}</div>}
                {friendsOnline > 0 && (
                  <div className="bg-green-400 avatar-online" />
                )}
              </div>
            </span>

            <div
              onClick={() => setMobileMenuOpen(true)}
              className="flex bg-gray-200 rounded-full md:hidden active:opacity-60 hover:opacity-70"
            >
              <div className="flex -mr-1 cursor-pointer md:hidden">
                <div className="relative flex items-center justify-center text-gray-600 rounded-full w-9 h-9">
                  <FaBars />
                </div>
              </div>

              <div className="flex items-center space-x-2 cursor-pointer md:hover:opacity-70">
                <UserAvatar className="w-9 h-9" />
              </div>
            </div>

            <span className="hidden md:flex has-tooltip">
              <span className="tooltip">{username}</span>
              <div
                onClick={() => setUserMenuActive(true)}
                className="flex items-center space-x-2 cursor-pointer md:hover:opacity-70"
              >
                <UserAvatar className="w-9 h-9" />
              </div>
            </span>
          </div>
        </div>
      </>
    );
  }

  function renderGuestMenu() {
    return (
      <>
        <div className="flex items-center">
          <div className="hidden md:flex">
            <NavLink
              key="register"
              exact
              className={styles.register}
              activeClassName={styles.current}
              href={"/register"}
            >
              {t("Sign up")}
            </NavLink>
          </div>
          <NavLink
            key="signIn"
            exact
            className={styles.signIn}
            activeClassName={styles.currentSignIn}
            href={"/signIn"}
          >
            {t("Log in")}
          </NavLink>
          {challengesMenuOpen && challengeCount > 0 && (
            <div className="absolute flex justify-end w-full top-8 ">
              <ChallengesMenu />
              <div
                className="hidden-overlay"
                onMouseUp={(e) => setChallengesMenuOpen(false)}
              />
            </div>
          )}
          <div
            onClick={() => setMobileMenuOpen(true)}
            className="flex ml-3 cursor-pointer md:hidden"
          >
            <div className="relative flex items-center justify-center text-gray-600 bg-gray-200 rounded-full w-9 h-9 hover:bg-gray-300">
              <FaBars />
            </div>
          </div>
        </div>

        {/* <div className={"ml-auto md:hidden"}>
            <button
              onClick={() => setMobileMenuOpen(true)}
              className="btn btn-icon btn-icon-lg btn-goast"
            >
              <FaBars />
            </button>
          </div> */}
      </>
    );
  }

  function setHrefLang(locale, currentPath) {
    if (
      (currentPath === "/termsofservice" ||
        currentPath === "/termsofservice/" ||
        currentPath === "/privacypolicy" ||
        currentPath === "/privacypolicy/") &&
      locale !== "en"
    ) {
      return (
        <link
          rel="canonical"
          href={`https://www.chesshotel.com${currentPath}`}
        />
      );
    }

    const locales = [
      "en",
      "es",
      "fr",
      "pt",
      "sv",
      "ru",
      "it",
      "de",
      "af",
      "ar",
      "az",
      "be",
      "bg",
      "bn",
      "bs",
      "ca",
      "cs",
      "da",
      "el",
      "et",
      "fa",
      "fi",
      "gl",
      "gu",
      "he",
      "hi",
      "hr",
      "hu",
      "hy",
      "id",
      "is",
      "ja",
      "ka",
      "kk",
      "kn",
      "ko",
      "lt",
      "lv",
      "ml",
      "mn",
      "mr",
      "nl",
      "nb",
      "pl",
      "ps",
      "pt-PT",
      "ro",
      "sk",
      "sl",
      "sr",
      "sw",
      "th",
      "tk",
      "tr",
      "uk",
      "ur",
      "uz",
      "vi",
      "zh-CN",
      "zh-TW",
    ];

    return locales.map((locale) => {
      if (locale == "en") {
        <link
          rel="alternate"
          href={`https://www.chesshotel.com${currentPath}`}
          hrefLang="x-default"
        />;
      } else {
        return (
          <link
            rel="alternate"
            hrefLang={locale}
            href={`https://www.chesshotel.com/${locale}${currentPath}`}
            key={locale}
          />
        );
      }
    });
  }

  const isGame = currentPath === gamePath;

  return (
    <>
      {
        <MobileMenuDrawer
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        />
      }

      {otherLoginShow && <OtherLoginPopup />}
      <div
        className={`sticky md:static z-30 top-0 flex items-center border-b border-b-altgrey-100 bg-white 
      pl-7 lg:pl-12 pr-5 lg:pr-12
      ${isGame ? "hidden md:flex" : ""}`}
      >
        <Head key="navigation">{setHrefLang(router.locale, currentPath)}</Head>
        {
          <div
            className={
              loadingBarVisible
                ? styles.loading + " " + styles.loadingActive
                : styles.loading
            }
          />
        }

        <div className="flex items-center space-x-2 lg:mr-12 md:mr-2">
          <Link href={memberLoggedIn ? "/lobby" : "/"}>
            <div className={isShortNav ? "crown" : "crown md:logo"} />
          </Link>
          {/* {!memberLoggedIn && (
            <div className={"ml-auto md:hidden"}>
              <button
                onClick={() => setMobileMenuOpen(true)}
                className="btn btn-icon btn-icon-lg btn-goast"
              >
                <FaBars />
              </button>
            </div>
          )} */}
        </div>
        <div
          className={`bg-white
        ${
          isShortNav
            ? "flex justify-between top-16 w-full h-[58px]"
            : "md:flex w-full justify-between h-[58px] md:h-[100px]"
        }`}
        >
          <div className={styles.menu}>
            <NavLink
              exact={false}
              prefetch={false}
              activeClassName={styles.current}
              href="/lobby"
            >
              {t("Play")}
            </NavLink>
            <NavLink exact activeClassName={styles.current} href="/players">
              {t("Players")}
            </NavLink>
            <NavLink exact activeClassName={styles.current} href="/puzzle">
              {t("Puzzles")}
            </NavLink>
            {SHOW_BLOG && (
              <NavLink exact activeClassName={styles.current} href="/blog">
                {t("Blog")}
              </NavLink>
            )}
          </div>
        </div>

        {!loading && (
          <div className={`${styles.authMenu}`}>
            {memberLoggedIn ? renderMemberMenu() : renderGuestMenu()}
          </div>
        )}

        {memberLoggedIn && visible && <FriendChat friendList={friendList} />}
      </div>
      {!isGamePath && gameId && gameId == gameIdSeen && gameEnded === false && (
        <Link
          href={"/game"}
          className=" flex justify-center text-base items-center w-full p-2 bg-red-50 border-b
        text-red-500 hover:text-red-600 space-x-1.5 font-semibold"
        >
          <FaExclamationCircle />
          <div>{t("Game in progress")}</div>
        </Link>
      )}
    </>
  );
}
