import { api } from "../services/api";
import Router from "next/router";
import { createReduxModule } from "hooks-for-redux";
import { siteDataStore } from "./siteData";
import { gaEvent } from "../lib/ga";
import { gamePlayStore } from "./gamePlay";
import { userStore } from "./user";
// import { websocket } from "../services/websocket";

export const ERROR_GAME_SYNC = 0; // used on server
export const ERROR_END_GAME_WRONG_ID = 1;
export const ERROR_TIME_UP_NOT_MY_TURN = 2;
export const ERROR_LEFT_GAME_STILL_THERE = 3;
export const ERROR_ELO_DONT_MATCH = 4;
export const ERROR_WON_LOWER_ELO = 5;
export const ERROR_TIME_FEN = 6;
export const ERROR_MOVE_RECIVED = 7;
export const ERROR_BOUNDRY = 8;
export const ERROR_CLOCK_STOPPED = 9;
export const ERROR_PLAYER = 10;

const initialState = {
  last: null,
  max: null,
  min: null,
  sum: 0,
  count: 0,
  average: 0,
  error: 0,
  offline: 0,
  resends: 0,
  pings: [],
  pingStart: null,
  wsClose: 0,
  userWsClose: 0,
  wsCloseErrorMsg: "",
  wsError: 0,
  wsErrorMsg: "",
  retries: 0,
  clientDisconn: 0,
  disconnectTime: null,
  disconnectTimeStamp: null,
  timeSinceDisconnect: null,
  endTimestamp: null,
  reconnected: 0,
  reconnectFail: null,
  updateReceived: null,
  updateReceivedTimeStamp: null,
  movesError: null,
  errorMsg: null,
  componentStack: null,
  gamePlayState: null,
  errorData: null,
};

export const [
  usePings,
  {
    moveSent,
    moveConfirmed,
    reset,
    setErrorData,
    setError,
    setErrorMsg,
    setOffline,
    setWsClose,
    setUserWsClose,
    setWsError,
    setWsCloseErrorMsg,
    setWsErrorMsg,
    setRetries,
    resetRetries,
    setClientDisconn,
    setDisconnectTime,
    setReconnected,
    setReconnectFail,
    setUpdateReceived,
    setMovesError,
    setGamePlayState,
  },
  pingStore,
] = createReduxModule("ping", initialState, {
  moveSent: (state) => ({
    ...state,
    pingStart: Date.now(),
  }),
  moveConfirmed: (state) => {
    const last = Date.now() - state.pingStart;
    const max = state.max != null ? Math.max(state.max, last) : last;
    const min = state.min != null ? Math.min(state.min, last) : last;
    const sum = last + state.sum;
    const count = state.count + 1;
    const average = sum / count;
    const pings = [...state.pings, last];

    return {
      ...state,
      last,
      sum,
      max,
      min,
      count,
      average,
      pings,
    };
  },
  reset: () => initialState,
  setErrorData: (state, errorData) => ({
    ...state,
    errorData,
  }),
  setError: (state, errorType) => {
    const endTimestamp = Date.now();
    const timeSinceDisconnect = endTimestamp - state.disconnectTimeStamp;

    return {
      ...state,
      timeSinceDisconnect,
      endTimestamp,
      error: state.error ? state.error * 10 + errorType : errorType,
    };
  },
  setOffline: (state) => ({
    ...state,
    offline: state.offline + 1,
  }),
  setWsClose: (state) => ({
    ...state,
    wsClose: state.wsClose + 1,
  }),
  setWsCloseErrorMsg: (state, msg) => ({
    ...state,
    wsCloseErrorMsg: msg,
  }),
  setUserWsClose: (state) => ({
    ...state,
    userWsClose: state.userWsClose + 1,
  }),
  setWsError: (state) => ({
    ...state,
    wsError: state.wsError + 1,
  }),
  setWsErrorMsg: (state, msg) => ({
    ...state,
    wsErrorMsg: msg,
  }),
  setRetries: (state) => ({
    ...state,
    retries: state.retries + 1,
  }),
  resetRetries: (state) => ({
    ...state,
    retries: 0,
  }),
  setClientDisconn: (state) => ({
    ...state,
    clientDisconn: state.clientDisconn + 1,
  }),
  setReconnected: (state) => ({
    ...state,
    reconnected: state.reconnected + 1,
    disconnectTime: Date.now() - state.disconnectTimeStamp,
  }),
  setDisconnectTime: (state) => ({
    ...state,
    disconnectTimeStamp: Date.now(),
  }),
  setReconnectFail: (state, msg) => ({
    ...state,
    reconnectFail: msg,
  }),
  setUpdateReceived: (state, gameId) => ({
    ...state,
    updateReceived: gameId,
    updateReceivedTimeStamp: Date.now(),
  }),
  setMovesError: (state, movesError) => ({
    ...state,
    movesError,
  }),
  setErrorMsg: (state, { errorMsg, componentStack }) => ({
    ...state,
    errorMsg,
    componentStack,
  }),
  setGamePlayState: (state, gamePlayState) => ({
    ...state,
    gamePlayState,
  }),
});

export function sendPingData() {
  const gameId = gamePlayStore.getState().gameId;
  const username = userStore.getState().username;
  const userId = userStore.getState().id;
  const ping = pingStore.getState();

  // const readyState = websocket.readyState;

  if (siteDataStore.getState().iframeMode) {
    gaEvent({ category: "game", action: "iframe game ended" });
    return;
  }

  const locale = Router.locale;

  const gameIdInt = gameId ? parseInt(gameId.substring(8)) : 0;

  gaEvent({ category: "game", action: "game ended" });

  if (ping.error) {
    const errorObj = {
      pings: ping.pings.length,
      ...(ping.errorData && { errorData: ping.errorData }),
      ...(ping.componentStack && { componentStack: ping.componentStack }),
      ...(ping.errorMsg && { errorMsg: ping.errorMsg }),
      ...(ping.movesError && { movesError: ping.movesError }),
      ...(ping.wsClose && { wsClose: ping.wsClose }),
      ...(ping.offline && { offline: ping.offline }),
      ...(ping.wsError && { wsError: ping.wsError }),
      ...(ping.wsCloseErrorMsg && { wsMsg: ping.wsCloseErrorMsg }),
      ...(ping.retries && { retries: ping.retries }),
      ...(ping.clientDisconn && { clientDisconn: ping.clientDisconn }),
      ...(ping.reconnected && { reconnected: ping.reconnected }),
      ...(ping.disconnectTime && { dTime: ping.disconnectTime }),
      ...(ping.disconnectTimeStamp && { dTimeStamp: ping.disconnectTimeStamp }),
      ...(ping.updateReceived && { updateGameId: ping.updateReceived }),
      ...(ping.gamePlayState && { gamePlayState: ping.gamePlayState }),
      ...(ping.timeSinceDisconnect && {
        timeSinceDisconnect: ping.timeSinceDisconnect,
      }),
      ...(ping.endTimestamp && { endTimestamp: ping.endTimestamp }),
      ...(ping.updateReceivedTimeStamp && {
        updateReceivedTimeStamp: ping.updateReceivedTimeStamp,
      }),
      username,
      // readyState,
    };

    const errorData = JSON.stringify(errorObj);
    gaEvent({
      category: "game",
      action: "game ended with error",
      label: ping.error.toString(),
    });

    if (ping.error == ERROR_TIME_UP_NOT_MY_TURN) {
      gaEvent({
        category: "game",
        action: "game ended with error 2 - time up not my turn",
        label: ping.error.toString(),
      });
    }
    if (ping.error == ERROR_LEFT_GAME_STILL_THERE) {
      gaEvent({
        category: "game",
        action: "game ended with error 3 - left game still there",
        label: ping.error.toString(),
      });
    }

    api
      .post("/error", {
        ...ping,
        errorData,
        userId,
        locale,
        gameId: gameIdInt,
      })
      .then(() => {
        reset();
      })
      .catch(() => {
        console.log("post ping failed");
      });
  } else {
    if (ping.count == 0) {
      reset();
      return;
    }

    api
      .post("/ping-stat", {
        min: ping.min,
        max: ping.max,
        average: ping.average,
      })
      .then(() => {
        reset();
      })
      .catch(() => {
        console.log("post ping failed");
      });
  }
}
