import React, { useState } from "react";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import dynamic from "next/dynamic";
const LangPopup = dynamic(() => import("./LangPopup"));

function Footer() {
  const [showLangs, setShowLangs] = useState(false);

  const { t } = useTranslation("common");

  return (
    <div className="flex flex-col items-center w-full pt-12 mt-auto bg-altgray-800 pb-28">
      {showLangs && <LangPopup setShowLangs={setShowLangs} />}
      <div className="flex items-center justify-center space-x-4">
        <div className="relative flex items-center w-40 ">
          <button
            onClick={() => {
              setShowLangs(!showLangs);
            }}
            className="w-40 h-10 px-3 py-2 text-center rounded-md hover:bg-altgray-650 text-altgray-500 bg-altgray-700"
          >
            {t("Language")}
          </button>
        </div>
      </div>

      <div className="flex p-6 space-x-3 text-sm text-altgray-500">
        <Link
          className="text-sm hover:opacity-70"
          href={"/privacypolicy"}
          prefetch={false}
        >
          Privacy policy
        </Link>
        <Link
          className="text-sm hover:opacity-70"
          href={"/termsofservice"}
          prefetch={false}
        >
          Terms of service
        </Link>
      </div>
    </div>
  );
}

export default Footer;
