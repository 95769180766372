import { GrowthBook } from "@growthbook/growthbook-react";
// import { gaEvent } from "../lib/ga";

export const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-y27X1fxnawegcIo1",
  enableDevMode: true,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system

    window.gtag("event", "experiment_viewed", {
      event_category: "experiment",
      experiment_id: experiment.key,
      variation_id: result.key,
    });
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});
