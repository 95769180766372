import { api } from "../services/api";
import { createReduxModule } from "hooks-for-redux";
import {
  FREE_TIER,
  UNLIMITED_TIER,
  BASIC_TIER,
  REGULAR_TIER,
} from "@chesshotel/constants";

const GUEST_START_LEAGUE_GAMES = 20;

const initialState = {
  tier: null,
  leagueGames: GUEST_START_LEAGUE_GAMES,
  analyzes: 0,
  updateTime: null,
  products: {
    [BASIC_TIER]: {
      en: "$2.99",
      fr: "€2.99",
      it: "€2.99",
      ru: "₽199",
      sv: "29 kr",
      pt: "R$ 9.90",
      es: "€2.99", // Spanish (Spain)
      de: "€2.99", // German (Germany)
      el: "€2.99", // Greek (Greece)
      et: "€2.99", // Estonian (Estonia)
      fi: "€2.99", // Finnish (Finland)
      lv: "€2.99", // Latvian (Latvia)
      lt: "€2.99", // Lithuanian (Lithuania)
      nl: "€2.99", // Dutch (Netherlands)
      "pt-PT": "€2.99", // Portuguese (Portugal), assuming "pt-PT" was intended here
      sk: "€2.99", // Slovak (Slovakia)
      sl: "€2.99", // Slovenian (Slovenia)
    },
    [REGULAR_TIER]: {
      en: "$3.99",
      fr: "€3.99",
      it: "€3.99",
      ru: "₽259",
      sv: "39 kr",
      pt: "R$ 12.90",
      es: "€3.99", // Spanish (Spain)
      de: "€3.99", // German (Germany)
      el: "€3.99", // Greek (Greece)
      et: "€3.99", // Estonian (Estonia)
      fi: "€3.99", // Finnish (Finland)
      lv: "€3.99", // Latvian (Latvia)
      lt: "€3.99", // Lithuanian (Lithuania)
      nl: "€3.99", // Dutch (Netherlands)
      "pt-PT": "€3.99", // Portuguese (Portugal), assuming "pt-PT" was intended here
      sk: "€3.99", // Slovak (Slovakia)
      sl: "€3.99", // Slovenian (Slovenia)
    },
    [UNLIMITED_TIER]: {
      en: "$7.99",
      fr: "€7.99",
      it: "€7.99",
      ru: "₽499",
      sv: "79 kr",
      pt: "R$ 19.90",
      es: "€7.99", // Spanish (Spain)
      de: "€7.99", // German (Germany)
      el: "€7.99", // Greek (Greece)
      et: "€7.99", // Estonian (Estonia)
      fi: "€7.99", // Finnish (Finland)
      lv: "€7.99", // Latvian (Latvia)
      lt: "€7.99", // Lithuanian (Lithuania)
      nl: "€7.99", // Dutch (Netherlands)
      "pt-PT": "€7.99", // Portuguese (Portugal), assuming "pt-PT" was intended here
      sk: "€7.99", // Slovak (Slovakia)
      sl: "€7.99", // Slovenian (Slovenia)
    },
  },
};

export const [
  useMembership,
  {
    doSetMembership,
    resetMembership,
    doDecrementLeagueGames,
    doDecrementAnalyzes,
    addOneLeagueGame,
    setTier,
  },
  membershipStore,
] = createReduxModule("membership", initialState, {
  doSetMembership: (state, membership) => {
    return {
      ...state,
      ...membership,
    };
  },
  resetMembership: () => initialState,
  doDecrementLeagueGames: (state) => {
    if (state.tier !== UNLIMITED_TIER && state.leagueGames > 0) {
      return {
        ...state,
        leagueGames: state.leagueGames - 1,
      };
    } else {
      return state;
    }
  },
  doDecrementAnalyzes: (state) => {
    return {
      ...state,
      analyzes: state.analyzes - 1,
    };
  },
  addOneLeagueGame: (state) => ({
    ...state,
    leagueGames: 1,
  }),
  setTier: (state, tier) => ({
    ...state,
    tier,
  }),
});

export function decrementAnalyzes() {
  const { tier, analyzes } = membershipStore.getState();
  if (tier !== UNLIMITED_TIER && analyzes > 0) {
    doDecrementAnalyzes();
    api.post("/membership/analyzes").catch(() => {
      console.log("update analyzes failed");
    });
  }
}

export function decrementLeagueGames() {
  const { tier, leagueGames } = membershipStore.getState();
  if (tier !== UNLIMITED_TIER && leagueGames > 0) {
    doDecrementLeagueGames();
  }
}

export function setMembership(membership) {
  doSetMembership(membership);
  const midnight = new Date();
  midnight.setUTCHours(24, 0, 0, 0);
  const now = new Date();

  const timeToUpdate =
    midnight.getTime() -
    now.getTime() +
    Math.round(Math.random() * 15000) +
    5000;

  setTimeout(() => {
    api.get("/membership").then((response) => {
      const { membership } = response.data;
      setMembership(membership);
    });
  }, timeToUpdate);
}
