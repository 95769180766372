import { createReduxModule } from "hooks-for-redux";

const initialState = {
  lastServerTime: null,
  timeUpdatedAt: null,
  opponentLastServerTime: null,
  userTime: null,
  opponentTime: null,
};

export const [
  useGameTime,
  {
    updateOpponentServerClock,
    updateUserServerClock,
    updateUserClock,
    updateOpponentClock,
    updateTimestamp,
    startTime,
  },
  gameTimeStore,
] = createReduxModule("gameTime", initialState, {
  startTime: (state, { userTime, opponentTime }) => ({
    ...state,
    userTime,
    opponentTime,
    lastServerTime: userTime,
    opponentLastServerTime: opponentTime,
    timeUpdatedAt: Date.now(),
  }),
  updateUserServerClock: (state, lastServerTime) => {
    return {
      ...state,
      lastServerTime,
      userTime: lastServerTime,
      timeUpdatedAt: Date.now(),
    };
  },
  updateOpponentServerClock: (state, opponentLastServerTime) => {
    return {
      ...state,
      opponentLastServerTime,
      opponentTime: opponentLastServerTime,
      timeUpdatedAt: Date.now(),
    };
  },
  updateTimestamp: (state) => ({
    ...state,
    timeUpdatedAt: Date.now(),
  }),
  updateUserClock: (state, userTime) => {
    return {
      ...state,
      userTime,
    };
  },
  updateOpponentClock: (state, opponentTime) => {
    return {
      ...state,
      opponentTime,
    };
  },
});
