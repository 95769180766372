import Avatar from "../components/Avatar";
import { useUser } from "../ducks/user";

export default function UserAvatar({ className }) {
  // avatarUpdateCount used for rerender when there is update
  const { fbId, avatarId, id, avatarType, avatarUpdateCount } = useUser();
  const userProps = {
    fbId,
    avatarId,
    id,
    avatarType,
    avatarUpdateCount,
    className,
  };

  return <Avatar {...userProps} />;
}
