import { Game, GamePlayer, LeagueScoreResult } from "@chesshotel/types";
import {
  Chat,
  CHAT_OPPONENT,
  ResultTypes,
  StatusTypes,
  VariantTypes,
} from "@chesshotel/constants";
import Router from "next/router";
import { clearGuestName, saveGuestName, userStore } from "./user";
import {
  moveConfirmed,
  sendPingData,
  setError,
  moveSent,
  ERROR_TIME_UP_NOT_MY_TURN,
  ERROR_LEFT_GAME_STILL_THERE,
  ERROR_ELO_DONT_MATCH,
  ERROR_WON_LOWER_ELO,
  ERROR_MOVE_RECIVED,
  ERROR_TIME_FEN,
  ERROR_END_GAME_WRONG_ID,
  setUpdateReceived,
  pingStore,
  setMovesError,
  ERROR_CLOCK_STOPPED,
  setGamePlayState,
  setErrorData,
} from "./ping";
import { playSound } from "../lib/sound";
import { createReduxModule } from "hooks-for-redux";
import { settingsStore } from "./settings";
import { siteDataStore } from "./siteData";
import { resetChallenges } from "./challenges";
import { clearInvites } from "./lobby";
import { closeChat } from "./friendChat";
import {
  gameTimeStore,
  startTime,
  updateOpponentClock,
  updateOpponentServerClock,
  updateTimestamp,
  updateUserClock,
  updateUserServerClock,
} from "./gameTime";
import { GamePlayWs } from "../services/wsOutRoutes";
// import { analyzePosition, resetGameReview } from "./analysis";
// import { growthbook } from "../util/growthbook";

const initialState = {
  gameId: null,
  gameIdSeen: null,
  offerDrawReceived: false,
  gameEnded: true,
  playingWhite: null,
  possibleMoves: null,
  rematchReceived: false,
  rematchSent: false,
  chatMessages: [],
  moves: [],
  positions: null,
  positionIndex: null,
  turns: 0,
  elo: null,
  previousElo: null,
  leaguePlay: false,
  opponent: {
    elo: 1200,
    timeUpdatedAt: null,
    previousElo: 1200,
    id: null,
    fbId: null,
    avatarId: 0,
    avatarType: null,
    username: "",
    displayName: "",
    isGuest: null,
    leaguePlay: null,
  },
  // moveTabSelected: true,
  gameCount: 0,
  stop: false,
  matches: [],
  lastCheatScore: 0,
  startTime: null,
  startInc: 0,
  variantId: null,
  displayGame: false,
  date: null,
  gamesPlayed: 0,
  timeUpdatedAt: null,
  isUserTurn: false,
  showAbort: false,
  result: null,
  status: null,
  modeId: null,
  leagueScoreResult: <LeagueScoreResult>null,
  gameSync: false,
};

export const [
  useGamePlay,
  {
    doGameStarted,
    doUpdateGame,
    makeMove,
    updateFenPosition,
    resetGamePlay,
    setAbort,
    chatCreated,
    updateElo,
    declineRematch,
    rematchSent,
    rematchReceived,
    doEndGame,
    offerDrawResponse,
    offerDrawReceived,
    updatePositionIndex,
    setGameIdSeen,
    setGameSync,
  },
  gamePlayStore,
] = createReduxModule("gamePlay", initialState, {
  doGameStarted: (
    state,
    { game, opponent, playingWhite, elo, possibleMoves, leaguePlay, isGuest }
  ) => {
    return {
      ...state,
      gameId: game.id,
      variantId: game.variant,
      playingWhite,
      possibleMoves,
      turns: 0,
      isUserTurn: playingWhite,
      startTime: game.clock.time,
      startInc: game.clock.inc,
      elo,
      leaguePlay,
      opponent: {
        ...opponent,
        displayName: opponent.isGuest
          ? opponent.username.substring(2)
          : opponent.username,
        previousElo: null,
      },
      previousElo: null,
      gameEnded: false,
      rematchReceived: false,
      offerDrawReceived: false,
      rematchSent: false,
      moves: [],
      positions: [game.fen],
      positionIndex: 0,
      gameCount: state.gameCount + 1,
      matches: [],
      chatMessages:
        state.opponent && state.opponent.username === opponent.username
          ? state.chatMessages
          : [],
      displayGame: false,
      date: null,
      showAbort: !isGuest,
    };
  },
  doUpdateGame: (
    state,
    {
      gameId,
      variantId,
      startTime,
      startInc,
      opponent,
      turn,
      playingWhite,
      leaguePlay,
      possibleMoves,
      moveNr,
      moves,
      positions,
      isPlaying,
      displayGame,
      date,
    }
  ) => {
    return {
      ...state,
      gameId,
      variantId,
      playingWhite,
      possibleMoves,
      turns: moveNr,
      isUserTurn: turn,
      startTime,
      startInc,
      leaguePlay,
      opponent: {
        ...opponent,
        displayName: opponent.isGuest
          ? opponent.username.substring(2)
          : opponent.username,
        previousElo: opponent.elo,
      },
      moves,
      positions,
      positionIndex: moves.length,
      gameEnded: !isPlaying,
      displayGame: displayGame,
      date,
    };
  },
  makeMove: (state, { possibleMoves, isUserTurn, move, fen, isGuest }) => {
    const newPositions = [...state.positions, fen];

    const turns = state.turns + 1;

    const showAbort = !isGuest && turns < 5;

    return {
      ...state,
      turns,
      possibleMoves,
      positions: newPositions,
      positionIndex: newPositions.length - 1,
      isUserTurn,
      moves: [...state.moves, move],
      showAbort,
    };
  },
  updateFenPosition: (state, fen) => {
    return {
      ...state,
      positions: state.positions.map((item, index) => {
        if (index !== state.positions.length - 1) {
          return item;
        }
        return fen;
      }),
      positionIndex: state.positions.length - 1,
    };
  },
  updatePositionIndex: (state, positionIndex) => {
    return {
      ...state,
      positionIndex,
    };
  },
  offerDrawReceived: (state) => ({ ...state, offerDrawReceived: true }),
  offerDrawResponse: (state) => ({ ...state, offerDrawReceived: false }),
  doEndGame: (state, { result, status, cheatScore, leagueScoreResult }) => {
    return {
      ...state,
      gameEnded: true,
      result,
      status,
      moveTabSelected: true,
      lastCheatScore: cheatScore,
      gamesPlayed: state.gamesPlayed + 1,
      showAbort: false,
      possibleMoves: {},
      leagueScoreResult,
    };
  },
  rematchReceived: (state) => ({ ...state, rematchReceived: true }),
  rematchSent: (state) => ({ ...state, rematchSent: true }),
  declineRematch: (state) => ({ ...state, rematchReceived: false }),
  updateElo: (state, { userElo, userOldElo, opponentElo, opponentOldElo }) => {
    return {
      ...state,
      elo: userElo,
      previousElo: userOldElo,
      opponent: {
        ...state.opponent,
        elo: opponentElo,
        previousElo: opponentOldElo,
      },
    };
  },
  chatCreated: (state, chat: Chat) => {
    return {
      ...state,
      chatMessages: [...state.chatMessages, chat],
      // moveTabSelected: false,
    };
  },
  setAbort: (state, showAbort) => ({ ...state, showAbort }),
  resetGamePlay: (state) => {
    return initialState;
  },
  setGameIdSeen: (state, gameIdSeen) => ({ ...state, gameIdSeen }),
  setGameSync: (state, gameSync) => ({ ...state, gameSync }),
});

export function opponentChatCreated(data) {
  const gameId = gamePlayStore.getState().gameId;
  if (data.gameId === gameId) {
    chatCreated({ msg: data.m, type: CHAT_OPPONENT });
  }
}

export interface GameStartedAction {
  game: Game;
  opponent: GamePlayer;
  elo: number;
  possibleMoves: object;
  playingWhite: boolean;
  leaguePlay: boolean;
}

export async function endGameWithSound(data: { game: Game }) {
  const { game } = data;

  const currentGameId = gamePlayStore.getState().gameId;
  if (currentGameId != null && currentGameId != game.id) {
    setError(ERROR_END_GAME_WRONG_ID);
    sendPingData();
    return;
  }

  if (settingsStore.getState().sound) {
    playSound("gamePlay");
  }

  const playingWhite =
    userStore.getState().username === game.whitePlayer.username;
  const variantId = game.variant;
  let userElo: number,
    userOldElo: number,
    opponentElo: number,
    opponentOldElo: number;
  let leagueGameResult: LeagueScoreResult;
  let leaguePlay: boolean;

  const lost =
    (playingWhite && ResultTypes.BLACK_WINNS === game.result) ||
    (!playingWhite && ResultTypes.WHITE_WINNS === game.result);

  const gameTime = gameTimeStore.getState();
  const gamePlay = gamePlayStore.getState();

  if (
    game.status === StatusTypes.TIME_UP &&
    lost &&
    !gamePlay.isUserTurn &&
    gameTime.userTime > 5000
  ) {
    setError(ERROR_TIME_UP_NOT_MY_TURN);
  }

  if (
    gameTime.userTime >= gamePlay.startTime * 60000 &&
    gamePlay.turns > 5 &&
    gamePlay.startInc == 0
  ) {
    setGamePlayState({
      gameId: gamePlay.gameId,
      turns: gamePlay.turns,
      opponent: gamePlay.opponent,
      startTime: gamePlay.startTime,
      gameTime,
    });
    setError(ERROR_CLOCK_STOPPED);
  }

  if (game.status === StatusTypes.QUIT && lost) {
    setError(ERROR_LEFT_GAME_STILL_THERE);
  }

  if (game.status !== StatusTypes.ABORT) {
    if (playingWhite) {
      userElo = game.eloResult.whiteNewElo;
      userOldElo = game.eloResult.whiteElo;
      opponentElo = game.eloResult.blackNewElo;
      opponentOldElo = game.eloResult.blackElo;
      leagueGameResult = game.whitePlayer.leagueGameResult;
      leaguePlay = game.whitePlayer.leaguePlay;
    } else {
      userElo = game.eloResult.blackNewElo;
      userOldElo = game.eloResult.blackElo;
      opponentElo = game.eloResult.whiteNewElo;
      opponentOldElo = game.eloResult.whiteElo;
      leagueGameResult = game.blackPlayer.leagueGameResult;
      leaguePlay = game.blackPlayer.leaguePlay;
    }
    if (leaguePlay) {
      const [leagues, membership] = await Promise.all([
        import("./leagues"),
        import("./membership"),
      ]);
      if (leagueGameResult) {
        if (userStore.getState().isGuest) {
          leagueGameResult.rank += 1;
        }
        leagues.updateLeagueScores(leagueGameResult);
      }
      membership.decrementLeagueGames();
    } else {
      const statsModule = await import("./stats");
      statsModule.updateStats({
        userElo,
        variantId,
        result: game.result,
        playingWhite,
      });
    }

    const localElo = gamePlay.elo;
    if (localElo != null && localElo !== userOldElo) {
      setError(ERROR_ELO_DONT_MATCH);
    }

    updateElo({ userElo, userOldElo, opponentElo, opponentOldElo });
  }

  const won =
    (!playingWhite && ResultTypes.BLACK_WINNS === game.result) ||
    (playingWhite && ResultTypes.WHITE_WINNS === game.result);
  if (userElo < userOldElo && won) {
    setError(ERROR_WON_LOWER_ELO);
  }
  endGame(game.status, game.result, leagueGameResult);
  sendPingData();
  clearTimeout(abortTimer);

  if (userStore.getState().isGuest) {
    clearGuestName();
  }
}

export function endGame(
  status: StatusTypes,
  result: ResultTypes,
  leagueScoreResult: LeagueScoreResult
) {
  const { playingWhite } = gamePlayStore.getState();

  const isWinner =
    (playingWhite && ResultTypes.WHITE_WINNS === result) ||
    (!playingWhite && ResultTypes.BLACK_WINNS === result);

  let userTimeUp = false;
  let opponentTimeUp = false;

  const cheatScore = 0;

  doEndGame({ result, status, cheatScore, leagueScoreResult });

  if (status === StatusTypes.TIME_UP) {
    userTimeUp = !isWinner;
    opponentTimeUp = isWinner;
  }

  if (userTimeUp) {
    updateUserServerClock(0);
  }
  if (opponentTimeUp) {
    updateOpponentServerClock(0);
  }
}

export function goToPosition(positionIndex: number) {
  const fen = gamePlayStore.getState().positions[positionIndex];
  if (fen) {
    updatePositionIndex(positionIndex);
  }
}
let runTimeId: NodeJS.Timeout;
function runTime() {
  const gt = gameTimeStore.getState();
  const gp = gamePlayStore.getState();
  if (gp.isUserTurn) {
    const lastUserTime = gt.userTime;
    const userTime = Math.max(
      0,
      gt.lastServerTime - (Date.now() - gt.timeUpdatedAt)
    );

    if (userTime < 10000) {
      if (Math.ceil(lastUserTime / 100) !== Math.ceil(userTime / 100)) {
        updateUserClock(userTime);
      }
    } else {
      if (Math.ceil(lastUserTime / 1000) !== Math.ceil(userTime / 1000)) {
        updateUserClock(userTime);
      }
    }
  } else {
    const lastOpponentTime = gt.opponentTime;

    const opponentTime = Math.max(
      0,
      gt.opponentLastServerTime - (Date.now() - gt.timeUpdatedAt)
    );
    if (opponentTime < 10000) {
      if (Math.ceil(lastOpponentTime / 100) !== Math.ceil(opponentTime / 100)) {
        updateOpponentClock(opponentTime);
      }
    } else {
      if (
        Math.ceil(lastOpponentTime / 1000) !== Math.ceil(opponentTime / 1000)
      ) {
        updateOpponentClock(opponentTime);
      }
    }
  }
  if (gp.gameEnded) {
    clearInterval(runTimeId);
  }
}

export function gameStarted(data: { game: Game }) {
  const { game } = data;
  const { gamePath } = siteDataStore.getState();
  Router.push(gamePath);

  const { username, isGuest } = userStore.getState();

  const playingWhite = game.whitePlayer.username === username;
  const opponent = playingWhite ? game.blackPlayer : game.whitePlayer;
  const elo = playingWhite ? game.whitePlayer.elo : game.blackPlayer.elo;
  const leaguePlay = playingWhite
    ? game.whitePlayer.leaguePlay
    : game.blackPlayer.leaguePlay;

  const possibleMoves = playingWhite ? game.dests : {};

  doGameStarted({
    game,
    opponent,
    playingWhite,
    elo,
    possibleMoves,
    leaguePlay,
    isGuest,
  });

  if (settingsStore.getState().sound) {
    playSound("gamePlay");
  }
  startTime({
    userTime: game.clock.whiteTime,
    opponentTime: game.clock.whiteTime,
  });

  runTimeId = setInterval(runTime, 100);

  setTimeout(() => {
    resetChallenges();
    clearInvites();
    closeChat();
  }, 1000);
  startAbortTimer();

  if (userStore.getState().isGuest) {
    saveGuestName();
  }
  // import("./analysis").then((module) => {
  //   module.startNewGamereview({ gameTime: game.clock.time, elo });
  // });
}

export async function updateGame(
  game: Game,
  whiteTime: number,
  blackTime: number
) {
  setUpdateReceived(game.id);
  const { gameId, gameEnded } = gamePlayStore.getState();
  const { username } = userStore.getState();
  const { gamePath } = siteDataStore.getState();

  if (gameId != null && gameId !== game.id) {
    console.log("update for wrong game");
    return;
  }

  if (!game.isPlaying && gameEnded) {
    console.log("Don't update old game if client has state that game ended");
    return;
  }

  // const gameUpdatePopup = growthbook.isOn("game-update-popup");

  // if (gameUpdatePopup) {
  //   setGameSync(true);
  // }

  // setTimeout(
  //   async () => {
  const playingWhite = game.whitePlayer.username === username;
  const { Chess } = await import("@chesshotel/chesslib");

  const chess =
    game.variant === VariantTypes.VARIANT_CHESS
      ? Chess()
      : Chess(game.startFen);

  const moveArr = game.moves;
  const firstPos =
    game.variant === VariantTypes.VARIANT_CHESS ? chess.fen() : game.startFen;
  const positions = moveArr.map((move) => {
    try {
      chess.move(move);
    } catch (e) {
      console.log(e);
    }
    return chess.fen();
  });
  positions.unshift(firstPos);

  const moves = chess.history({ verbose: true });

  const turn =
    (playingWhite && game.halfMoveCount % 2 === 0) ||
    (!playingWhite && game.halfMoveCount % 2 === 1);
  const possibleMoves = turn ? game.dests : {};

  const player = playingWhite ? game.whitePlayer : game.blackPlayer;
  const opponent = playingWhite ? game.blackPlayer : game.whitePlayer;
  const userTime = playingWhite ? whiteTime : blackTime;
  const opponentTime = playingWhite ? blackTime : whiteTime;
  const leaguePlay = playingWhite
    ? game.whitePlayer.leaguePlay
    : game.blackPlayer.leaguePlay;

  doUpdateGame({
    turn,
    moveNr: game.halfMoveCount,
    userTime,
    opponentTime,
    opponent,
    playingWhite,
    leaguePlay,
    startTime: game.clock.time,
    startInc: game.clock.inc,
    variantId: game.variant,
    gameId: game.id,
    moves,
    positions,
    // elo: player.elo,
    possibleMoves,
    isPlaying: game.isPlaying,
    displayGame: false,
    date: null,
  });

  if (game.result || game.result === ResultTypes.DRAW) {
    endGameWithSound({ game });
  } else {
    if (Router.pathname != gamePath) {
      Router.push(gamePath);
    }

    startTime({
      userTime,
      opponentTime,
    });

    clearInterval(runTimeId);
    runTimeId = setInterval(runTime, 100);
  }
  //   },
  //   gameUpdatePopup ? 500 : 0
  // );
}

// Opponents move received. Updates game position
export function moveReceived(data: {
  move: any;
  dests: any;
  fen: string;
  wTime: number;
  bTime: number;
  nr: number;
  id: any;
}) {
  let { move, fen, nr } = data;
  const possibleMoves = data.dests;
  const whiteTime = data.wTime;
  const blackTime = data.bTime;
  const gameId = data.id;

  const isGuest = userStore.getState().isGuest;

  const gamePlay = gamePlayStore.getState();
  const moves = gamePlay.moves;
  const clientGameId = gamePlay.gameId;
  const playingWhite = gamePlay.playingWhite;
  let userTime: number, opponentTime: number;
  if (playingWhite) {
    userTime = whiteTime;
    opponentTime = blackTime;
  } else {
    userTime = blackTime;
    opponentTime = whiteTime;
  }

  if (gameId !== clientGameId) {
    return;
  }

  if (moves.length + 1 !== nr) {
    GamePlayWs.update();

    setError(ERROR_MOVE_RECIVED);
    return;
  }
  makeMove({ move, isUserTurn: true, possibleMoves, fen, isGuest });

  updateOpponentServerClock(opponentTime);
  updateUserServerClock(userTime);
  clearTimeout(abortTimer);
  // clearTimeout(gameSyncTimer);
  // clearTimeout(lastSendTimer);

  // import("./analysis").then((module) => {
  //   setTimeout(() => {
  //     module.analyzePosition(fen, move.from + move.to);
  //   }, 200);
  // });
}

export function timeAndFENReceived(data: {
  t: number;
  fen: string;
  nr: number;
  id: any;
}) {
  // clearTimeout(abortTimer);
  // clearTimeout(lastSendTimer);

  const time = data.t;
  let { fen, nr } = data;
  const gameId = data.id;
  const gamePlay = gamePlayStore.getState();
  const moves = gamePlay.moves;
  const clientGameId = gamePlay.gameId;

  if (moves.length !== nr || gameId !== clientGameId) {
    const movesError = {
      movesLength: moves.length,
      nr,
      isUserTurn: gamePlay.isUserTurn,
      isWhite: gamePlay.playingWhite,
      moves: gamePlay.moves.map((move) => move.from + move.to),
      gameId,
      clientGameId,
      now: Date.now(),
      pingStart: pingStore.getState().pingStart,
    };

    GamePlayWs.update();
    setError(ERROR_TIME_FEN);
    setMovesError(movesError);
  } else {
    moveConfirmed();
    updateFenPosition(fen);
    updateUserServerClock(time);
    // const lastMove = moves[moves.length - 1];

    // import("./analysis").then((module) => {
    //   module.analyzePosition(fen, lastMove.from + lastMove.to);
    // });
  }
}

let abortTimer;
function startAbortTimer() {
  clearTimeout(abortTimer);
  try {
    abortTimer = setTimeout(() => {
      const gamePlay = gamePlayStore.getState();
      const isUserTurn = gamePlay.isUserTurn;
      const turns = gamePlay.turns;
      const gameEnded = gamePlay.gameEnded;
      if (!gameEnded && !isUserTurn && turns < 4) {
        setAbort(true);
      }
    }, 35000);
  } catch (e) {
    console.log("startAbortTimer error");
  }
}

// let gameSyncTimer;
// let lastSendTimer;
export function sendUserMove(move) {
  const isGuest = userStore.getState().isGuest;
  makeMove({
    move,
    isUserTurn: false,
    possibleMoves: {},
    fen: move.fen,
    isGuest,
  });
  updateTimestamp();

  const { turns, gameId } = gamePlayStore.getState();

  const serverMove = {
    move,
    i: turns,
    id: gameId,
  };

  GamePlayWs.sendMove(serverMove);

  moveSent();

  if (turns < 4) {
    startAbortTimer();
  }
}
