import { api } from "./api";
import Router from "next/router";
import { setLoadingBar } from "../ducks/siteData";
import { setUserData, setLoading } from "../ducks/signIn";
import { userStore } from "../ducks/user";

export function loadFacebook() {
  const body = document.getElementsByTagName("body")[0];

  const aScript = document.createElement("script");
  aScript.type = "text/javascript";
  aScript.src = "https://connect.facebook.net/en_US/sdk.js";
  body.appendChild(aScript);

  window.fbAsyncInit = function () {
    window.FB.init({
      appId: "821357816461898",
      cookie: true,
      xfbml: true,
      version: "v19.0",
    });
    window.FB.AppEvents.logPageView();
  };
}

export function signOutFB() {
  if (window.FB) {
    window.FB.getLoginStatus((response) => {
      if (response.status === "connected") {
        window.FB.logout();
      }
    });
  } else {
    setTimeout(() => {
      signOutFB();
    }, 1000);
  }
}

function signInOrWillCreateFBUser(accessToken: string) {
  api
    .post("/auth/facebook/token", { access_token: accessToken })
    .then((response) => {
      console.log("signInOrWillCreateFBUser", response);
      if (response.data.willCreateFacebookUser) {
        Router.push(
          `/register/addUsername?accountType=facebook&token=${accessToken}`
        );
      } else {
        setUserData(response.data);
        setLoading(false);
        Router.replace("/lobby");
        window.scrollTo(0, 0);
      }
    })
    .catch(() => {
      setLoading(false);
    })
    .then(() => {
      setLoadingBar(false);
    });
}

export function upgradeFB() {
  setLoadingBar(true);
  if (window.FB) {
    window.FB.login(
      (response) => {
        if (response.status === "connected") {
          const accessToken = response.authResponse.accessToken;
          const { id, fbId } = userStore.getState();

          api
            .post("/auth/facebook/upgrade", {
              access_token: accessToken,
              id,
              fbId,
            })
            .then((response) => {
              setUserData(response.data);
              setLoading(false);
              Router.replace("/lobby");
              window.scrollTo(0, 0);
            })
            .catch(() => {
              setLoading(false);
            })
            .then(() => {
              setLoadingBar(false);
            });
        } else {
          setLoadingBar(false);
        }
      },
      { scope: "gaming_profile" }
    );
  } else {
    setTimeout(() => {
      upgradeFB();
    }, 1000);
  }
}

export function signInFB() {
  setLoadingBar(true);
  if (window.FB) {
    window.FB.login(
      (response) => {
        if (response.status === "connected") {
          const accessToken = response.authResponse.accessToken;
          signInOrWillCreateFBUser(accessToken);
        } else {
          setLoadingBar(false);
        }
      },
      { scope: "gaming_profile" }
    );
  } else {
    setTimeout(() => {
      signInFB();
    }, 100);
  }
}
