import { FaDiceSix } from "@react-icons/all-files/fa/FaDiceSix";
import { FaBolt } from "@react-icons/all-files/fa/FaBolt";
import { FaDragon } from "@react-icons/all-files/fa/FaDragon";
import { BulletIcon } from "../icons/BulletIcon";
import { ModeIds, VARIANT_CHESS960, TimeControl } from "@chesshotel/constants";
import { FullLeagueScore } from "@chesshotel/types";
import { FaPaperPlane } from "@react-icons/all-files/fa/FaPaperPlane";

// export function debounce(func, wait, immediate) {
//   let timeout;
//   return function () {
//     const context = this;
//     const args = arguments;
//     const later = function () {
//       console.log("RAN")
//       timeout = null;
//       if (!immediate) func.apply(context, args);
//     };
//     const callNow = immediate && !timeout;
//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//     if (callNow) func.apply(context, args);
//   };
// }

export function convertToNumber(value: any) {
  if (typeof value === "string") return Number(value);
  else return value;
}

export function formatTime(time, inc) {
  const incText = inc > 0 ? ` + ${inc}s` : "";
  return `${time}m${incText}`;
}
export function formatTimeMin(time, inc) {
  const incText = inc > 0 ? ` + ${inc} s` : "";
  return `${time} min ${incText}`;
}

export const getVariantName = (variant) => {
  if (typeof variant === "string") {
    variant = Number(variant);
  }
  if (variant === VARIANT_CHESS960) {
    return "Chess960";
  } else {
    return "Standard";
  }
};

function getTwoDigitDateFormat(monthOrDate) {
  return monthOrDate < 10 ? "0" + monthOrDate : "" + monthOrDate;
}

export function formatDate(date) {
  const d = new Date(date);
  return (
    d.getFullYear() +
    "-" +
    getTwoDigitDateFormat(d.getMonth() + 1) +
    "-" +
    getTwoDigitDateFormat(d.getDate())
  );
}

export function getModeIcon(modeId) {
  if (modeId === ModeIds.CHESS960) {
    return <FaDiceSix />;
  } else if (modeId === ModeIds.BULLET) {
    return <BulletIcon />;
  } else if (modeId === ModeIds.BLITZ) {
    return <FaBolt />;
  } else if (modeId === ModeIds.RAPID) {
    return <FaDragon />;
  } else if (modeId === ModeIds.DAILY) {
    return <FaPaperPlane />;
  }
}

// export const iconColor = (leagueName) => {
//   switch (leagueName) {
//     case "bronze":
//       return "#c46b32";
//     case "silver":
//       return "#c4c4c4";
//     case "gold":
//       return "#ffbc04";
//     case "platinum":
//       return "#c4c4c4";
//     case "diamond":
//       return "#4c80e1";
//     case "master":
//       return "#c4c4c4";
//     case "grandmaster":
//       return "#c4c4c4";
//     case "challenger":
//       return "#c4c4c4";
//     default:
//       return "#888888";
//   }
// };

export const getBottom = (leagueName, medalNum) => {
  let bottom: string;
  switch (leagueName) {
    case "bronze":
      switch (medalNum) {
        case 8:
          return (bottom = "76px");
        case 25:
          return (bottom = "77px");
        case 50:
          return (bottom = "75px");
        case 100:
          return (bottom = "80px");
      }
      break;
    case "diamond":
      switch (medalNum) {
        case 8:
          return (bottom = "77px");
        case 25:
          return (bottom = "77px");
        case 50:
          return (bottom = "75px");
        case 100:
          return (bottom = "80px");
      }
      break;
  }
  return bottom;
};

// export const getMedalFileName = ({ leagueName, rank }) => {
//   let medalNum;
//   let medalName;
//   if (rank > 50) {
//     medalNum = 100;
//   } else if (rank > 25) {
//     medalNum = 50;
//   } else if (rank > 8) {
//     medalNum = 25;
//   } else {
//     medalNum = 8;
//   }
//   switch (leagueName) {
//     case "placement":
//       return { url: `bronze100.svg`, medalNum: 100 };
//     case "bronze":
//       medalName = "bronze";
//       break;
//     case "silver":
//       medalName = "silver";
//       break;
//     case "gold":
//       medalName = "gold";
//       break;
//     case "platinum":
//       medalName = "platinum";
//       break;
//     case "diamond":
//       medalName = "diamond";
//       break;
//     case "master":
//       medalName = "master";
//       break;
//     case "grandmaster":
//       return { url: `grandmaster.svg`, medalNum };
//   }

//   return {
//     url: `${medalName}${medalNum}.svg`,
//     medalNum,
//   };
// };

export function firstToUpper(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getModeId(
  time: number,
  variant: number,
  timeControl: TimeControl
) {
  if (timeControl == TimeControl.DAILY) {
    return ModeIds.DAILY;
  }
  if (variant === VARIANT_CHESS960) {
    return ModeIds.CHESS960;
  } else if (time < 3) {
    return ModeIds.BULLET;
  } else if (time < 10) {
    return ModeIds.BLITZ;
  } else {
    return ModeIds.RAPID;
  }
}

export function getModeNameCapitalFirst(modeId, t) {
  if (modeId === ModeIds.CHESS960) {
    return "Chess960";
  } else if (modeId === ModeIds.BULLET) {
    return "Bullet";
  } else if (modeId === ModeIds.BLITZ) {
    return "Blitz";
  } else if (modeId === ModeIds.RAPID) {
    return "Rapid";
  } else {
    return t("Daily");
  }
}

export function getHeighestScore(scores: FullLeagueScore[]) {
  if (scores == null || scores.length === 0) {
    return null;
  }
  let highestElo = scores[0].elo;
  let highestScore = scores[0];
  for (let i = 0; i < scores.length; i++) {
    const score = scores[i];
    if (highestElo < score.elo) {
      highestElo = score.elo;
      highestScore = score;
    }
  }
  return highestScore;
}
