// import { createIcon } from "@chakra-ui/icon";

// export const BulletIcon = createIcon({
//   displayName: "bullet",
//   viewBox: "0 0 100 100",
//   d: "M38.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27a1.49 1.49 0 0 0-1.5 1.48v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49a1.49 1.49 0 0 0-1.5 1.48v5.93A1.49 1.49 0 0 0 36.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.49zM38 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 50.9.3a1.51 1.51 0 0 0-1.8 0 31.19 31.19 0 0 0-12.6 24.92v1.48a1.49 1.49 0 0 0 1.5 1.48zM4.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27A1.49 1.49 0 0 0 0 32.63v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49A1.49 1.49 0 0 0 0 81.58v5.93A1.49 1.49 0 0 0 1.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.49zM3 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 15.9.3a1.51 1.51 0 0 0-1.8 0A31.19 31.19 0 0 0 1.5 25.22v1.48A1.49 1.49 0 0 0 3 28.18zM74.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27a1.49 1.49 0 0 0-1.5 1.48v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49a1.49 1.49 0 0 0-1.5 1.48v5.93A1.49 1.49 0 0 0 71.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.48zM73 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 85.9.3a1.51 1.51 0 0 0-1.8 0 31.19 31.19 0 0 0-12.6 24.92v1.48a1.49 1.49 0 0 0 1.5 1.48z",
// });

// var prefix = "fas";
// var iconName = "bullet";
// var width = 100;
// var height = 99;
// var ligatures = [];
// var unicode = "cust57";
// var svgPathData =
//   "M38.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27a1.49 1.49 0 0 0-1.5 1.48v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49a1.49 1.49 0 0 0-1.5 1.48v5.93A1.49 1.49 0 0 0 36.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.49zM38 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 50.9.3a1.51 1.51 0 0 0-1.8 0 31.19 31.19 0 0 0-12.6 24.92v1.48a1.49 1.49 0 0 0 1.5 1.48zM4.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27A1.49 1.49 0 0 0 0 32.63v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49A1.49 1.49 0 0 0 0 81.58v5.93A1.49 1.49 0 0 0 1.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.49zM3 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 15.9.3a1.51 1.51 0 0 0-1.8 0A31.19 31.19 0 0 0 1.5 25.22v1.48A1.49 1.49 0 0 0 3 28.18zM74.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27a1.49 1.49 0 0 0-1.5 1.48v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49a1.49 1.49 0 0 0-1.5 1.48v5.93A1.49 1.49 0 0 0 71.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.48zM73 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 85.9.3a1.51 1.51 0 0 0-1.8 0 31.19 31.19 0 0 0-12.6 24.92v1.48a1.49 1.49 0 0 0 1.5 1.48z";

// export const BulletIcon = {
//   prefix: prefix,
//   iconName: iconName,
//   icon: [width, height, ligatures, unicode, svgPathData],
// };

// export const BulletIcon = ({ size, fill } = null) => {
//   return (
//     <svg
//       width={size ? size : "1em"}
//       fill={fill}
//       height={size ? size : "1em"}
//       viewBox="0 0 100 100"
//       role="img"
//     >
//       <path
//         fill={fill}
//         d="M38.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27a1.49 1.49 0 0 0-1.5 1.48v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49a1.49 1.49 0 0 0-1.5 1.48v5.93A1.49 1.49 0 0 0 36.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.49zM38 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 50.9.3a1.51 1.51 0 0 0-1.8 0 31.19 31.19 0 0 0-12.6 24.92v1.48a1.49 1.49 0 0 0 1.5 1.48zM4.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27A1.49 1.49 0 0 0 0 32.63v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49A1.49 1.49 0 0 0 0 81.58v5.93A1.49 1.49 0 0 0 1.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.49zM3 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 15.9.3a1.51 1.51 0 0 0-1.8 0A31.19 31.19 0 0 0 1.5 25.22v1.48A1.49 1.49 0 0 0 3 28.18zM74.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27a1.49 1.49 0 0 0-1.5 1.48v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49a1.49 1.49 0 0 0-1.5 1.48v5.93A1.49 1.49 0 0 0 71.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.48zM73 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 85.9.3a1.51 1.51 0 0 0-1.8 0 31.19 31.19 0 0 0-12.6 24.92v1.48a1.49 1.49 0 0 0 1.5 1.48z"
//       ></path>
//     </svg>
//   );
// };
export const BulletIcon = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 100 100" role="img">
      <path
        fill="currentColor"
        d="M38.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27a1.49 1.49 0 0 0-1.5 1.48v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49a1.49 1.49 0 0 0-1.5 1.48v5.93A1.49 1.49 0 0 0 36.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.49zM38 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 50.9.3a1.51 1.51 0 0 0-1.8 0 31.19 31.19 0 0 0-12.6 24.92v1.48a1.49 1.49 0 0 0 1.5 1.48zM4.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27A1.49 1.49 0 0 0 0 32.63v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49A1.49 1.49 0 0 0 0 81.58v5.93A1.49 1.49 0 0 0 1.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.49zM3 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 15.9.3a1.51 1.51 0 0 0-1.8 0A31.19 31.19 0 0 0 1.5 25.22v1.48A1.49 1.49 0 0 0 3 28.18zM74.5 80.1h21v-1.48h-21v1.48zm25.5-3V32.63a1.49 1.49 0 0 0-1.5-1.48h-27a1.49 1.49 0 0 0-1.5 1.48v44.5a1.49 1.49 0 0 0 1.5 1.48v1.49a1.49 1.49 0 0 0-1.5 1.48v5.93A1.49 1.49 0 0 0 71.5 89h27a1.49 1.49 0 0 0 1.5-1.48v-5.94a1.49 1.49 0 0 0-1.5-1.48v-1.48a1.49 1.49 0 0 0 1.5-1.48zM73 28.18h24a1.49 1.49 0 0 0 1.5-1.48v-1.48A31.19 31.19 0 0 0 85.9.3a1.51 1.51 0 0 0-1.8 0 31.19 31.19 0 0 0-12.6 24.92v1.48a1.49 1.49 0 0 0 1.5 1.48z"
      ></path>
    </svg>
  );
};
