import { api } from "../services/api";

import { createReduxModule } from "hooks-for-redux";

const initialState = {
  isSubscribed: false,
  canSubscribe: false,
};

export const [useWebPush, { setIsSubscribed, setCanSubscribe }, webPushStore] =
  createReduxModule("webPush", initialState, {
    setIsSubscribed: (state, isSubscribed) => ({
      ...state,
      isSubscribed,
    }),
    setCanSubscribe: (state, canSubscribe) => ({
      ...state,
      canSubscribe,
    }),
  });

const base64ToUint8Array = (base64) => {
  const padding = "=".repeat((4 - (base64.length % 4)) % 4);
  const b64 = (base64 + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(b64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

let registration;

export function initWebPush() {
  if (typeof window !== "undefined" && "serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((reg) => {
        console.log("Service Worker registered:", reg);

        if (reg.pushManager) {
          reg.pushManager.getSubscription().then((sub) => {
            //@ts-ignore
            const expirationTime = sub?.expirationTime;

            if (
              sub &&
              !(expirationTime && Date.now() > expirationTime - 5 * 60 * 1000)
            ) {
              setIsSubscribed(true);
              console.log("subscribed");
            } else {
              console.log("not subscribed");
              setIsSubscribed(false);
            }
          });

          registration = reg;
          setCanSubscribe(true);
        } else {
          console.log("pushManager not available");
          setCanSubscribe(false);
        }
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
        setCanSubscribe(false);
      });
  }
}

export async function subscribe() {
  const { isSubscribed, canSubscribe } = webPushStore.getState();
  if (isSubscribed || !canSubscribe) {
    return;
  }

  const sub = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: base64ToUint8Array(
      process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY
    ),
  });
  api
    .post("/web-push", { subscription: sub })
    .then((res) => {
      // setSubscription(sub);
      document.cookie = "webpushSubscribed=true; path=/; max-age=7776000"; // 90 days
      setIsSubscribed(true);
      console.log("web push subscribed!");
      console.log(sub);
    })
    .catch((error) => {
      console.log(error);
    });
}
