import { api } from "../services/api";
import { Friend, SharedPlayer } from "@chesshotel/types";
import { createReduxModule } from "hooks-for-redux";
import { playersStore } from "./players";
import { FriendWs } from "../services/wsOutRoutes";

interface FriendsState {
  friendSearch: string[];
  sentFriendRequests: number[];
  friendList: Friend[];
  friendRequests: Friend[];
  loadingFriendList: boolean;
  loadingFriendRequests: boolean;
}

const initialState: FriendsState = {
  friendSearch: [],
  sentFriendRequests: [],
  friendList: [],
  friendRequests: [],
  loadingFriendList: true,
  loadingFriendRequests: false,
};

const [
  useFriends,
  {
    doFriendSearch,
    setSentFriendRequests,
    addSentFriendRequest,
    doSetFriends,
    removeFriend,
    setFriendRequests,
    loadingReplyFriendRequest,
    friendOffline,
    friendOnline,
    friendsOnline,
  },
  friendStore,
] = createReduxModule("friends", initialState, {
  doFriendSearch: (state, friendSearch) => ({ ...state, friendSearch }),
  setSentFriendRequests: (state, sentFriendRequests) => ({
    ...state,
    sentFriendRequests,
  }),
  addSentFriendRequest: (state, friendId) => ({
    ...state,
    sentFriendRequests: [...state.sentFriendRequests, friendId],
  }),
  doSetFriends: (state, { friendList, players }) => {
    if (!friendList) {
      return state;
    }

    return {
      ...state,
      friendList: friendList.map((friend) => {
        const friendInList = state.friendList.find(
          (f) => f.username === friend.username
        );
        if (friendInList) {
          return friendInList;
        } else {
          const friendFound = players.find((player: SharedPlayer) => {
            return player.u === friend.username;
          });
          if (friendFound) {
            return { ...friend, online: true };
          } else {
            return { ...friend, online: false };
          }
        }
      }),
      loadingFriendList: false,
    };
  },
  removeFriend: (state, userId) => ({
    ...state,
    friendList: state.friendList.filter((friend) => friend.id !== userId),
  }),
  setFriendRequests: (state, friendRequests) => ({
    ...state,
    friendRequests,
    loadingFriendRequests: false,
  }),
  loadingReplyFriendRequest: (state, index) => ({
    ...state,
    friendRequests: state.friendRequests.map((friend, i) =>
      i === index ? { ...friend, loading: true } : friend
    ),
  }),
  friendOffline: (state, username) => ({
    ...state,
    friendList: state.friendList.map((friend) => {
      if (friend.username === username) {
        return { ...friend, online: false };
      } else {
        return friend;
      }
    }),
  }),
  friendOnline: (state, player: SharedPlayer) => ({
    ...state,
    friendList: state.friendList.map((friend) => {
      if (friend.username === player.u) {
        return { ...friend, online: true };
      } else {
        return friend;
      }
    }),
  }),
  friendsOnline: (state, players) => ({
    ...state,
    friendList: state.friendList.map((friend) => {
      const friendFound = players.find((player: SharedPlayer) => {
        return player.u === friend.username;
      });
      if (friendFound) {
        return { ...friend, online: true };
      } else {
        return { ...friend, online: false };
      }
    }),
  }),
});

export {
  useFriends,
  doFriendSearch,
  setSentFriendRequests,
  addSentFriendRequest,
  removeFriend,
  setFriendRequests,
  loadingReplyFriendRequest,
  friendOffline,
  friendOnline,
  friendsOnline,
  friendStore,
};

export function setFriends(friendList: Friend[]) {
  const players = playersStore.getState().players;
  doSetFriends({ friendList, players });
}

export function friendRequest(friendId: number, friendName: string) {
  api
    .post("/relationship/friend-request", { friendId })
    .then((response) => {
      addSentFriendRequest(friendId);

      console.log("friendName", friendName);

      FriendWs.friendRequest(friendName);
    })
    .catch(() => {
      console.log("friendRequest failed");
    });
}

export function getFriends() {
  api
    .get("/relationship/friends")
    .then((response) => {
      setFriends(response.data);
    })
    .catch(() => {
      console.log("getFriends failed");
    });
}

export function getFriendReqests() {
  api
    .get("/relationship/friend-requests")
    .then((response) => {
      setFriendRequests(response.data);
    })
    .catch(() => {
      console.log("getFriendReqests failed");
    });
}
