import { api } from "../services/api";
import { ModeIds } from "@chesshotel/constants";
import { LeagueScoreResult } from "@chesshotel/types";
import { createReduxModule } from "hooks-for-redux";
import Cookies from "universal-cookie";
import { userStore } from "./user";
import { LeagueScore } from "@chesshotel/models";
const cookies = new Cookies();

const initialLeagueScore = {
  leagueName: "placement",
  gameNr: 0,
  elo: 1200,
  points: 0,
  rank: null,
  divisionId: null,
  bonusPool: 0,
};

const initialLeagueScores = {
  [ModeIds.BULLET]: initialLeagueScore,
  [ModeIds.BLITZ]: initialLeagueScore,
  [ModeIds.RAPID]: initialLeagueScore,
  [ModeIds.CHESS960]: initialLeagueScore,
  [ModeIds.DAILY]: initialLeagueScore,
};

const initialState = {
  divisionIds: {
    //used only for guests
    [ModeIds.BULLET]: null,
    [ModeIds.BLITZ]: null,
    [ModeIds.RAPID]: null,
    [ModeIds.CHESS960]: null,
    [ModeIds.DAILY]: null,
  },

  leagueScores: initialLeagueScores,
  divisionScores: {
    [ModeIds.BULLET]: [],
    [ModeIds.BLITZ]: [],
    [ModeIds.RAPID]: [],
    [ModeIds.CHESS960]: [],
    [ModeIds.DAILY]: [],
  },
  leagueScoresLoaded: false,
  seasonNr: null,
  lastSeasonNr: null,
  lastSeason: initialLeagueScores,
  hasMedals: false,
  hasLastSeason: false,
  didViewSeasonPopup: false,
  hasPlayedCurrentSeason: false,
};

export const [
  useLeagues,
  {
    doSetLeagueScores,
    updateLeagueScores,
    loadLeagueScores,
    resetLeagueScores,
    doSetDidViewSeasonPopup,
    setDivisionScores,
    setLeagueScoresLoaded,
  },
  leaguesStore,
] = createReduxModule("leagues", initialState, {
  doSetLeagueScores: (
    state,
    {
      leagueScores,
      divisionScores,
      lastSeason,
      seasonNr,
      lastSeasonNr,
      hasMedals,
      hasLastSeason,
      hasPlayedCurrentSeason,
      didViewSeasonPopup,
    }
  ) => {
    return {
      ...state,
      leagueScores,
      divisionScores,
      leagueScoresLoaded: true,
      lastSeason,
      seasonNr,
      lastSeasonNr,
      hasMedals,
      hasLastSeason,
      hasPlayedCurrentSeason,
      didViewSeasonPopup,
    };
  },
  updateLeagueScores: (state, leagueGameResult: LeagueScoreResult) => {
    const { leagueScore, rank } = leagueGameResult;
    const hasMedals = leagueScore?.leagueName !== "placement";
    return {
      ...state,
      leagueScores: {
        ...state.leagueScores,
        [leagueScore.modeId]: {
          ...state.leagueScores[leagueScore.modeId],
          ...leagueScore,
          rank,
        },
      },
      divisionIds: {
        ...state.divisionIds,
        [leagueScore.modeId]: leagueScore.divisionId,
      },
      leagueScoresLoaded: true,
      hasPlayedCurrentSeason: true,
      hasMedals,
    };
  },
  loadLeagueScores: (state) => ({ ...state, leagueScoresLoaded: true }),
  resetLeagueScores: () => initialState,
  doSetDidViewSeasonPopup: (state) => ({ ...state, didViewSeasonPopup: true }),
  setDivisionScores: (state, { divisionScores, modeId }) => ({
    ...state,
    leagueScoresLoaded: true,
    divisionScores: {
      ...state.divisionScores,
      [modeId]: divisionScores,
    },
  }),
  setLeagueScoresLoaded: (state, leagueScoresLoaded) => ({
    ...state,
    leagueScoresLoaded,
  }),
});

const FOURHUNDRED_DAYS = 34560000000;
export function setDidViewSeasonPopup() {
  const seasonNr = leaguesStore.getState().seasonNr;
  const year = new Date().getFullYear();
  cookies.set(year + "-" + seasonNr, true, {
    path: "/",
    maxAge: FOURHUNDRED_DAYS,
  });

  doSetDidViewSeasonPopup();
}

export function setLeagueScores(
  leagueScoresArr,
  divisionScoresArr,
  lastSeason,
  seasonId
) {
  const seasonOfYear = seasonId % 4 > 0 ? seasonId % 4 : 4;
  const lastSeasonOfYear = (seasonId - 1) % 4 > 0 ? (seasonId - 1) % 4 : 4;
  const username = userStore.getState().username;
  const leagueScores = { ...initialState.leagueScores };

  const year = new Date().getFullYear();
  const didViewSeasonPopup = cookies.get(year + "-" + seasonOfYear) != null;

  let hasMedals = false;
  let hasPlayedCurrentSeason = false;
  const hasLastSeason =
    lastSeason != null && Object.keys(lastSeason).length > 0;

  leagueScoresArr.forEach((league) => {
    // league.rank = 1;
    leagueScores[league.modeId] = league;

    if (league.leagueName !== "placement") {
      hasMedals = true;
    }
    if (league.gameNr > 0) {
      hasPlayedCurrentSeason = true;
    }
  });

  // If new season han no games give elo of last season.
  for (const key in leagueScores) {
    if (leagueScores[key].gameNr === 0 && lastSeason[key]) {
      leagueScores[key] = { ...leagueScores[key], elo: lastSeason[key].elo };
    }
  }

  const divisionScores = {};
  divisionScores[ModeIds.BULLET] = [];
  divisionScores[ModeIds.BLITZ] = [];
  divisionScores[ModeIds.RAPID] = [];
  divisionScores[ModeIds.CHESS960] = [];
  divisionScores[ModeIds.DAILY] = [];

  divisionScoresArr.forEach((score) => {
    divisionScores[score.modeId].push(score);
    // if (leagueScores[score.modeId].points < score.points) {
    //   leagueScores[score.modeId].rank += 1;
    // }
  });

  const sortFn = (a, b) => {
    if (b.points === a.points) {
      if (b.username === username) {
        return 1;
      } else if (a.username === username) {
        return -1;
      } else {
        return b.points - a.points;
      }
    } else {
      return b.points - a.points;
    }
  };

  divisionScores[ModeIds.BULLET].sort(sortFn);
  divisionScores[ModeIds.BLITZ].sort(sortFn);
  divisionScores[ModeIds.RAPID].sort(sortFn);
  divisionScores[ModeIds.CHESS960].sort(sortFn);
  divisionScores[ModeIds.DAILY].sort(sortFn);

  doSetLeagueScores({
    leagueScores,
    divisionScores,
    lastSeason,
    seasonNr: seasonOfYear,
    lastSeasonNr: lastSeasonOfYear,
    hasMedals,
    hasLastSeason,
    hasPlayedCurrentSeason,
    didViewSeasonPopup,
  });
}

export function getLeagueScores() {
  api
    .get("/league-scores")
    .then((response) => {
      const { leagueScores, divisionScores, lastSeason, seasonId } =
        response.data;
      setLeagueScores(leagueScores, divisionScores, lastSeason, seasonId);
    })
    .catch((e) => {
      console.error("league-scores failed", e);
    });
}

export function getGuestDivisionScores(modeId: number) {
  const divisionId = leaguesStore.getState().divisionIds[modeId];
  const leagueScore = leaguesStore.getState().leagueScores[modeId];

  if (divisionId == null) {
    return;
  }

  setLeagueScoresLoaded(false);

  api
    .get(`/division-scores/${divisionId}`)
    .then((response) => {
      const { divisionScores } = response.data;

      const updatedDivisionScores = addGuestToScores(
        divisionScores,
        leagueScore
      );
      setDivisionScores({ divisionScores: updatedDivisionScores, modeId });
    })
    .catch((e) => {
      console.error("league-scores failed", e);
    });
}

function addGuestToScores(
  divisionScores: LeagueScore[],
  leagueScore: LeagueScore
) {
  divisionScores = divisionScores.sort((a, b) => b.points - a.points);

  const { points } = leagueScore;
  leagueScore.username = leagueScore.username.startsWith("G*")
    ? leagueScore.username.substring(2)
    : leagueScore.username;

  for (let i = 0; i < divisionScores.length; i++) {
    const score = divisionScores[i];

    if (points > score.points) {
      divisionScores.splice(i, 0, leagueScore);
      return divisionScores;
    }
  }
  divisionScores.push(leagueScore);
  return divisionScores;
}
