import {
  MAX_INVITES,
  VARIANT_CHESS,
  LIVE_GAME_TAB,
  ModeIds,
  TimeControl,
} from "@chesshotel/constants";
// import { QuickMatch } from "@chesshotel/types";
import { createReduxModule } from "hooks-for-redux";
import { userStore } from "./user";

export interface Invite {
  id: number;
  username: string;
  isGuest: boolean;
  elo: number;
  time: number;
  inc: number;
  variant: number;
  gameNr: number;
  removing?: boolean;
  blink?: boolean;
}

interface LobbyState {
  invites: Invite[];
  tmpInvites: Invite[];
  isRemoving: boolean;
  time: number;
  inc: number;
  variant: number;
  turnLength: number;
  modeId: number;
  timeControl: TimeControl;
  // customTime: number;
  // customInc: number;
  // customVariant: number;
  leaguePlay: boolean;
  gamesCreated: any[];
  customGamePopupVisible: boolean;
  challengePopupVisible: boolean;
  tab: number;
  filterGuestTime: boolean;
}

const initialState: LobbyState = {
  invites: [],
  tmpInvites: [],
  isRemoving: false,
  time: 10,
  inc: 0,
  variant: VARIANT_CHESS,
  turnLength: 1,
  modeId: ModeIds.RAPID,
  timeControl: TimeControl.LIVE,
  // customTime: null,
  // customInc: null,
  // customVariant: null,
  leaguePlay: true,
  gamesCreated: [],
  customGamePopupVisible: false,
  challengePopupVisible: false,
  tab: LIVE_GAME_TAB,
  filterGuestTime: false,
};

export const [
  useLobby,
  {
    doInviteReceived,
    updateInvites,
    addBlink,
    removeBlink,
    removePlayersInvites,
    doCreateGame,
    chooseGame,
    // setCustomGame,
    clearInvites,
    setLeaguePlay,
    showCustomGamePopup,
    showChallengePopup,
    setTab,
    setGuestFilterTime,
    reset,
    setBlinking,
  },
  lobbyStore,
] = createReduxModule("lobby", initialState, {
  doInviteReceived: (state, invite) => {
    if (state.isRemoving || state.invites.length >= MAX_INVITES)
      return { ...state, tmpInvites: [...state.tmpInvites, invite] };
    else return { ...state, invites: [...state.invites, invite] };
  },
  updateInvites: (state) => {
    const filteredInvites = state.invites.filter((invite) => {
      return !invite.hasOwnProperty("removing");
    });

    const filteredTmpInvites = state.tmpInvites.filter((invite) => {
      return !invite.hasOwnProperty("removing");
    });

    const invitesToAddLen = MAX_INVITES - filteredInvites.length;
    const invitesToAdd = filteredTmpInvites.slice(0, invitesToAddLen);
    const invitesLeft = filteredTmpInvites.slice(invitesToAddLen);
    const invites = filteredInvites.concat(invitesToAdd);

    return {
      ...state,
      invites,
      tmpInvites: invitesLeft,
    };
  },
  addBlink: (state) => ({
    ...state,
    isRemoving: true,
    invites: [...state.invites].map((invite) => {
      return { ...invite, blink: true };
    }),
  }),
  removeBlink: (state) => ({
    ...state,
    isRemoving: false,
    invites: [...state.invites].map((invite) => {
      return { ...invite, blink: false };
    }),
  }),
  removePlayersInvites: (state, players: string[]) => {
    return {
      ...state,
      invites: state.invites.map((invite) => {
        if (players.indexOf(invite.username) !== -1)
          return { ...invite, removing: true };
        else return invite;
      }),
      tmpInvites: state.tmpInvites.map((invite) => {
        if (players.indexOf(invite.username) !== -1)
          return { ...invite, removing: true };
        else return invite;
      }),
    };
  },
  doCreateGame: (state, { time, inc, variant }) => ({
    ...state,
    gamesCreated: [...state.gamesCreated, { time, inc, variant }],
  }),
  chooseGame: (
    state,
    { time, inc, variant, modeId, turnLength, timeControl }
  ) => {
    if (TimeControl.LIVE == timeControl) {
      return {
        ...state,
        time,
        inc,
        variant,
        modeId,
        timeControl,
      };
    } else {
      return {
        ...state,
        inc,
        variant,
        modeId,
        turnLength,
        timeControl,
      };
    }
  },
  // setCustomGame: (state, { time, inc, variant }) => ({
  //   ...state,
  //   customTime: time,
  //   customInc: inc,
  //   customVariant: variant,
  // }),
  clearInvites: (state) => ({
    ...state,
    gamesCreated: [],
    invites: [],
    tmpInvites: [],
  }),
  setLeaguePlay: (state, leaguePlay) => ({
    ...state,
    leaguePlay,
    invites: [],
    tmpInvites: [],
  }),
  showCustomGamePopup: (state, isVisible) => ({
    ...state,
    customGamePopupVisible: isVisible,
  }),
  showChallengePopup: (state, isVisible) => ({
    ...state,
    challengePopupVisible: isVisible,
  }),
  setTab: (state, tab) => ({
    ...state,
    tab,
  }),
  setGuestFilterTime: (state, filterGuestTime) => ({
    ...state,
    filterGuestTime,
  }),
  reset: () => initialState,
  setBlinking: (state, { time, inc, variant, blinking }) => {
    return {
      ...state,
      gamesCreated: state.gamesCreated.map((game) => {
        if (
          time === game.time &&
          inc === game.inc &&
          variant === game.variant
        ) {
          game.blink = blinking;
        }
        return game;
      }),
    };
  },
});

function notDuplicate(invite: Invite, invites: Invite[]) {
  for (let i = 0; i < invites.length; i++) {
    const currInvite = invites[i];
    if (
      currInvite.username === invite.username &&
      currInvite.time === invite.time &&
      currInvite.inc === invite.inc &&
      currInvite.variant === invite.variant
    ) {
      return false;
    }
  }
  return true;
}

export function inviteReceived(quickMatch) {
  const { id, time, inc, variant, player } = quickMatch;
  const { elo, gameNr, isGuest, username } = player;
  const user = userStore.getState();

  const invite = {
    id,
    time,
    inc,
    variant,
    elo,
    gameNr,
    isGuest,
    username,
  };

  const { invites, tmpInvites, filterGuestTime } = lobbyStore.getState();

  const filterInvite =
    (!invite.isGuest && user.username.substring(0, 7) === "G*Guest") ||
    (invite.username.substring(0, 7) === "G*Guest" && !user.isGuest);

  if (filterInvite && filterGuestTime) {
    return;
  }

  const allInvites = invites.concat(tmpInvites);
  if (notDuplicate(invite, allInvites)) {
    doInviteReceived(invite);
  }
}

function hasRemovingInvites(invites: Invite[]) {
  for (let i = 0; i < invites.length; i++) {
    if (invites[i].removing) {
      return true;
    }
  }
  return false;
}

let ticker: any;
let timeOut: any;
export function enterLobby() {
  clearInterval(ticker);
  updateInvites();
  ticker = setInterval(() => {
    const invites = lobbyStore.getState().invites;
    const isRemoving = hasRemovingInvites(invites);
    isRemoving && addBlink();
    clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      updateInvites();
      isRemoving && removeBlink();
    }, 500);
  }, 4000);
}

// export function exitLobby() {
//   clearInterval(ticker);
//   clearInterval(timeOut);
// }
