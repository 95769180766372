import { CHAT_OPPONENT, Chat } from "@chesshotel/constants";
import { createReduxModule } from "hooks-for-redux";
// import { registerWsListener } from "./websocket";

interface FriendChatState {
  visible: boolean;
  open: boolean;
  friendId: number;
  chatMessages: { [friendId: number]: Chat[] };
  unreadMessages: { [friendId: number]: number };
}

const initialState: FriendChatState = {
  visible: false,
  open: false,
  friendId: null,
  chatMessages: {},
  unreadMessages: {},
};

const [
  useFriendChat,
  {
    friendChatVisible,
    friendChatOpen,
    setChatFriend,
    createFriendChat,
    closeChat,
  },
  friendChatStore,
] = createReduxModule("friendChat", initialState, {
  friendChatVisible: (state, visible) => ({
    ...state,
    visible,
  }),
  friendChatOpen: (state, open) => ({
    ...state,
    open,
    unreadMessages: {
      ...state.unreadMessages,
      [state.friendId]:
        open && state.friendId && state.unreadMessages[state.friendId]
          ? 0
          : state.unreadMessages[state.friendId],
    },
  }),
  setChatFriend: (state, friendId) => ({
    ...state,
    friendId,
    open: true,
    visible: true,
    unreadMessages: {
      ...state.unreadMessages,
      [friendId]: state.unreadMessages[friendId]
        ? 0
        : state.unreadMessages[friendId],
    },
  }),
  createFriendChat: (state, { friendId, msg, type }) => {
    const unreadMessages =
      type === CHAT_OPPONENT && (!state.visible || !state.open)
        ? state.unreadMessages[friendId]
          ? state.unreadMessages[friendId] + 1
          : (state.unreadMessages[friendId] = 1)
        : state.unreadMessages[friendId];

    return {
      ...state,
      chatMessages: {
        ...state.chatMessages,
        [friendId]: state.chatMessages[friendId]
          ? [...state.chatMessages[friendId], { msg, type }]
          : [{ msg, type }],
      },
      unreadMessages: {
        ...state.unreadMessages,
        [friendId]: unreadMessages,
      },
    };
  },
  closeChat: (state) => ({ ...state, open: false }),
});

export {
  useFriendChat,
  friendChatVisible,
  friendChatOpen,
  setChatFriend,
  createFriendChat,
  closeChat,
  friendChatStore,
};
