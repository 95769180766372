export function getSize() {
  const screenHeight = window.innerHeight;
  let screenWidth = window.innerWidth;
  let boardSize;
  const isDesktop = screenWidth > 768;
  if (isDesktop) {
    const adSize = 160; //showAd ? (screenWidth < 1025 ? 160 : 160) : 0;
    boardSize = Math.min(
      Math.round((screenHeight - 90) * 0.87),
      screenWidth - 400 - adSize
    );

    if (boardSize > 704) {
      boardSize = 704;
    } else if (boardSize < 440) {
      boardSize = 440;
    } else {
      boardSize -= boardSize % 8;
    }
  } else {
    screenWidth -= screenWidth % 2;
    boardSize = screenWidth;
  }
  return { boardSize, isDesktop };
}
