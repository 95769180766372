import { createReduxModule } from "hooks-for-redux";
import { Notification } from "@chesshotel/models";
import { api } from "../services/api";
import { getGame } from "../modules/daily/cGamePlayActions";

export const NOT_VIEWED = 1;
export const VIEWED = 0;
interface NotificationState {
  list: Notification[];
}

const initialState: NotificationState = {
  list: [],
};

export const [
  useNotifications,
  { setNotifications, addNotification, setViewed },
  notificationsStore,
] = createReduxModule("notifications", initialState, {
  setNotifications: (state, notifications) => ({
    ...state,
    list: notifications,
  }),
  addNotification: (state, notification) => ({
    ...state,
    list: [...state.list, notification],
  }),
  setViewed: (state, id) => ({
    ...state,
    list: state.list.filter((item) => {
      return item.id != id;
    }),
  }),
});

export function deleteNotification(gameId) {
  const notis = notificationsStore
    .getState()
    .list.find((item) => item.entityId == gameId);

  if (notis) {
    const { id } = notis;
    setViewed(id);
    api.delete(`/notification/${id}`).catch((e) => {
      console.error(e);
    });
  }
}

export async function notificationReceived(notification: Notification) {
  await getGame(notification.entityId);
  addNotification(notification);
}
