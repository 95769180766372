import { createReduxModule } from "hooks-for-redux";

export const ADS_PROVIDER_PLAYWIRE = "ADS_PROVIDER_PLAYWIRE";
export const ADS_PROVIDER_ADSENSE = "ADS_PROVIDER_ADSENSE";
export const ADS_PROVIDER_YANDEX = "ADS_PROVIDER_YANDEX";

const initialState = {
  playersOnline: null,
  loadingBarVisible: false,
  adsVisible: false,
  adsProvider: null,
  gamePath: "/game",
  lobbyPath: "/lobby",
  iframeMode: false,
  adSenseVideo: false,
  imaLoaded: false,
  sideAd: true,
};

export const [
  useSiteData,
  {
    setLoadingBar,
    setPlayersOnline,
    setAdsense,
    setAdsenseVideo,
    setImaLoaded,
    setPlaywire,
    setYandex,
    setIframeMode,
    // setConsent,
    // setGamePath,
    setSideAd,
  },
  siteDataStore,
] = createReduxModule("siteData", initialState, {
  setLoadingBar: (state, loadingBarVisible) => ({
    ...state,
    loadingBarVisible,
  }),
  setPlayersOnline: (state, playersOnline) => ({
    ...state,
    playersOnline,
  }),
  setAdsense: (state) => ({
    ...state,
    adsVisible: true,
    adsProvider: ADS_PROVIDER_ADSENSE,
  }),
  setYandex: (state) => ({
    ...state,
    adsVisible: true,
    adsProvider: ADS_PROVIDER_YANDEX,
    sideAd: false,
  }),
  setAdsenseVideo: (state) => ({
    ...state,
    adSenseVideo: true,
  }),
  setImaLoaded: (state) => ({
    ...state,
    imaLoaded: true,
  }),
  setPlaywire: (state) => ({
    ...state,
    adsVisible: true,
    adsProvider: ADS_PROVIDER_PLAYWIRE,
  }),
  setIframeMode: (state) => ({
    ...state,
    gamePath: "/iframe/game",
    lobbyPath: "/iframe/lobby",
    iframeMode: true,
  }),
  // setConsent: (state) => ({
  //   ...state,
  //   consent: true,
  // }),
  // setGamePath: (state, path) => ({
  //   ...state,
  //   gamePath: path,
  // }),
  setSideAd: (state, sideAd) => ({
    ...state,
    sideAd,
  }),
});
