import styles from '../pages/styles/Home.module.css';
import Image from "next/image";

function getLang() {
  if (navigator.languages !== undefined) {
    return navigator.languages[0].substring(0, 2);
  } else if (navigator.language) {
    return navigator.language.substring(0, 2);
  }
}

const langObj = {
  en: {
    text1: "Your web browser is out of date.",
    text2: "Update your browser for more security,",
    text3: "speed and the best experience on this site.",
    update: "Update your browser",
  },
  fr: {
    text1: "Votre navigateur Web est obsolète.",
    text2: "Mettez à jour votre navigateur pour plus de sécurité,",
    text3: "de vitesse et la meilleure expérience sur ce site.",
    update: "Mettez à jour votre navigateur",
  },
  it: {
    text1: "Il tuo browser web non è aggiornato.",
    text2: "Aggiorna il tuo browser per maggiore sicurezza,",
    text3: "velocità e la migliore esperienza su questo sito.",
    update: "Aggiorna il tuo browser",
  },
  pt: {
    text1: "Seu navegador da web está desatualizado.",
    text2: "Atualize seu navegador para mais segurança,",
    text3: "velocidade e a melhor experiência neste site.",
    update: "Atualize seu navegador",
  },
  ru: {
    text1: "Ваш веб-браузер устарел.",
    text2: "Обновите свой браузер, чтобы повысить безопасность,",
    text3: "скорость и удобство использования этого сайта.",
    update: "Обновите ваш браузер",
  },
  sv: {
    text1: "Din webbläsare är inaktuell.",
    text2: "Uppdatera din webbläsare för mer säkerhet,",
    text3: "snabbhet och den bästa upplevelsen på den här webbplatsen.",
    update: "Uppdatera din webbläsare",
  },
};

export default function HomeIE() {
  let lang = getLang();
  if (langObj[lang] == null) {
    lang = "en";
  }

  return (
    <div>
      <div className={styles.jumbotronBg}>
        <div className={styles.jumbotron}>
          <Image
            layout="fixed"
            alt="chesshotel"
            className={styles.logo}
            src="/images/logo-white2x.png"
            width="162"
            height="65"
          />
          <div className={styles.ieNotice}>
            {langObj[lang].text1}
            <br /> {langObj[lang].text2}
            <br /> {langObj[lang].text3}
            <br />
            <br />
            <a href="http://outdatedbrowser.com">
              <button className={styles.ie11Button}>
                {langObj[lang].update}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}




