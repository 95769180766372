import { createReduxModule } from "hooks-for-redux";
const initialState = {
  players: [],
  search: "",
  lastUpdate: Date.now(),
};

const [
  usePlayers,
  {
    removePlayer,
    addPlayer,
    setPlayers,
    playersStartedPlaying,
    playersStoppedPlaying,
    setSearch,
  },
  playersStore,
] = createReduxModule("players", initialState, {
  removePlayer: (state, username) => {
    return {
      ...state,
      players: state.players.filter((player) => player.u !== username),
    };
  },
  addPlayer: (state, { u, elo, id, g }) => {
    let notInList = true;
    for (let i = 0; i < state.players.length; i++) {
      const player = state.players[i];
      if (player.u === u) {
        notInList = false;
        break;
      }
    }

    if (notInList) {
      return {
        ...state,
        players: [...state.players, { u, elo, p: 0, id, g }],
      };
    } else {
      return state;
    }
  },
  setPlayers: (state, players) => ({
    ...state,
    players,
  }),
  playersStartedPlaying: (state, { player1, player2 }) => {
    return {
      ...state,
      players: state.players.map((player) => {
        if (player.u === player1 || player.u === player2) {
          return { ...player, p: 1 };
        } else {
          return player;
        }
      }),
    };
  },
  playersStoppedPlaying: (state, { player1, player2, elo1, elo2 }) => {
    return {
      ...state,
      players: state.players.map((player) => {
        if (player.u === player1) {
          return { ...player, elo: elo1, p: 0 };
        } else if (player.u === player2) {
          return { ...player, elo: elo2, p: 0 };
        } else {
          return player;
        }
      }),
    };
  },
  setSearch: (state, search) => ({ ...state, search }),
});

export {
  usePlayers,
  removePlayer,
  addPlayer,
  setPlayers,
  playersStartedPlaying,
  playersStoppedPlaying,
  setSearch,
  playersStore,
};
