// store.js
import { setStore, createStore } from "hooks-for-redux";
import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

const logDispatch = (store) => (next) => (action) => {
  // console.log(action.type, action.payload);
  return next(action);
};

setStore(
  createStore(
    {},
    process.env.NODE_ENV === "development" &&
      composeWithDevTools(applyMiddleware(logDispatch))
  )
);
