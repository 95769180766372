import { websocket } from "./websocket";
import { RematchChallenge, WsMove } from "@chesshotel/types";

export function emit(cmd: string, obj: any = {}, again = true) {
  obj.cmd = cmd;
  const message = JSON.stringify(obj);

  try {
    websocket.send(message);
  } catch (e) {
    setTimeout(() => {
      if (again) {
        emit(cmd, obj, false);
      }
    }, 1000);
  }
}

export const LoginWs = {
  loginServer: (user) => emit("user", user),
};

export const GamePlayWs = {
  sendMove: (move: WsMove) => emit("move", move),
  update: () => emit("update"),
  sendResign: () => emit("rsgn"),
  sendOfferDraw: () => emit("ofer"),
  sendAbort: () => emit("abort"),
  offerDrawResponse: (accept: boolean) => emit("reof", { accept }),
  sendRematch: () => emit("rema"),
  sendChat: (msg: string) => emit("chat", { m: msg }),
  saveProgress: () => emit("saveProgress"),
  // sendGameSync: (turns, gameId, moves) =>
  //   emit("gameSync", { turns, gameId, moves }),
};

export const PlayerWs = {
  block: (userId: number, username: string) =>
    emit("block", { userId, username }),
  unblock: (userId: number) => emit("unblock", { userId }),
};

export const DailyLobbyWs = {
  addInvite: (invite) => emit("addDailyInvite", invite),
  // sendPrivChallenge: (challenge) => emit("corrSendPrivChallenge", challenge),
  // joinPrivGame: (game) => emit("joinPrivUserDailyGame", game),
  joinGame: (game) => emit("joinDailyGame", game),
  // removePrivChall: (challenge) => emit("removePrivChall", challenge),
};

export const DailyGameWs = {
  updateGame: (game) => emit("dailyUpdate", game),
  sendChat: (chat) => emit("dailyChat", chat),
  sendMove: (move) => emit("dailyMove", move),
  sendRematch: (challenge: RematchChallenge) => emit("dailyRematch", challenge),
};

export const LobbyWs = {
  acceptInvite: (invite) => emit("acce", invite),
  createQuickMatch: (game) => emit("quik", game),
};

export const FriendWs = {
  friendRequest: (username: string) => emit("friendRequest", { username }),
  replyFriend: (username: string) => emit("friendResponse", { username }),
  sendChat: (chat) => emit("fchat", chat),
};

export const ChallengeWs = {
  declineChallenge: (challenge) => emit("declineChallenge", challenge),
  acceptLiveChallenge: (challenge) => emit("acceptLiveChallenge", challenge),
  acceptDailyChallenge: (challenge) => emit("acceptDailyChallenge", challenge),
  sendChallenge: (challenge) => emit("challenge", challenge),
  removeChallenge: (challenge) => emit("removeChallenge", challenge),
};
