import { createFriendChat, friendChatStore } from "../ducks/friendChat";
import {
  playersStartedPlaying,
  playersStoppedPlaying,
  removePlayer,
  addPlayer,
  setPlayers,
} from "../ducks/players";
import { loginComplete } from "../ducks/signIn";
import { duplicateUser } from "../ducks/user";
import { setPlayersOnline } from "../ducks/siteData";
import { inviteReceived, removePlayersInvites } from "../ducks/lobby";
import { CHAT_OPPONENT } from "@chesshotel/constants";
import {
  getFriendReqests,
  getFriends,
  friendOnline,
  friendsOnline,
  friendOffline,
} from "../ducks/friends";
import { playSound } from "../lib/sound";
import { settingsStore } from "../ducks/settings";
import {
  gameStarted,
  moveReceived,
  offerDrawReceived,
  opponentChatCreated,
  rematchReceived,
  timeAndFENReceived,
  updateGame,
  endGameWithSound,
} from "../ducks/gamePlay";

import { AcceptedPrivateChallenge } from "@chesshotel/types";
import {
  addChallenge,
  declineChallenge,
  removeChallenge,
  removeChallengeByUsername,
} from "../ducks/challenges";
import { checkWs, otherUserLogin } from "./websocket";
import {
  getGame,
  updateOpponentStatus,
} from "../modules/daily/cGamePlayActions";
import Router from "next/router";

const inRoutes = {};
export function inRoute(cmd: string, controller: (d: any) => void) {
  inRoutes[cmd] = controller;
}

export function routeIncommingMessage(data) {
  const controller = inRoutes[data.cmd];
  if (controller) {
    controller(data);
  }
}

/* CHALLENGE */
inRoute("CHALLENGE", (d) => addChallenge(d));
inRoute("REMOVE_CHALLENGE", (d) => removeChallenge(d.id));
inRoute("DECLINE_CHALLENGE", (d) => {
  declineChallenge(d.id);
});

/* LOBBY */
inRoute("QUIK", (d) => {
  inviteReceived(d);
});

inRoute("QUIK_LIST", (d) => {
  d.list.forEach((quickMatch) => inviteReceived(quickMatch));
});

/* SIGN IN AND WS-CONNECTION */
inRoute("COMP", (d) => {
  setPlayersOnline(d.players);
  loginComplete();
});
inRoute("DUPL", () => duplicateUser());
inRoute("OTHE", (d) => {
  otherUserLogin();
});
inRoute("WS", (d) => checkWs(d.ws));

/* PLAYERS */
inRoute("UPDT", (d) => {
  setTimeout(() => {
    const players = { player1: d.u1, player2: d.u2 };
    playersStartedPlaying(players);
    removePlayersInvites([d.u1, d.u2]);
  }, 1000);
});
inRoute("REMO", (d) => {
  removePlayer(d.username);
  removePlayersInvites([d.username]);
  friendOffline(d.username);
  updateOpponentStatus(d.userId, false);
  removeChallengeByUsername(d.username);
});
inRoute("BLOCK", (d) => {
  removePlayer(d.username);
  removePlayersInvites([d.username]);
});
inRoute("LIST", (d) => {
  setPlayers(d.list);
  friendsOnline(d.list);
});

inRoute("ADDU", (d) => {
  addPlayer(d.player);
  friendOnline(d.player);
  updateOpponentStatus(d.player.id, true);
});
inRoute("RANK", (d) => {
  playersStoppedPlaying({
    player1: d.u1,
    elo1: d.elo1,
    player2: d.u2,
    elo2: d.elo2,
  });
});
inRoute("ONLI", (d) => setPlayersOnline(d.players));

/* GAMEPLAY */
inRoute("PLAY", (d) => gameStarted(d));
inRoute("MOVE", (d) => moveReceived(d));
inRoute("TIME", (d) => timeAndFENReceived(d));
inRoute("END", (d) => endGameWithSound(d));
inRoute("OFER", () => offerDrawReceived());
inRoute("REMA", () => rematchReceived());
inRoute("CHAT", (d) => opponentChatCreated(d));
inRoute("INFO", (d) => {
  updateGame(d.game, d.whiteTime, d.blackTime);
});

/* FRIENDS */
inRoute("FCHAT", (d) => {
  const { visible, open } = friendChatStore.getState();
  if (!visible || (visible && !open)) {
    try {
      if (settingsStore.getState().sound) {
        playSound("chat");
      }
    } catch (e) {
      console.log("sound not working");
    }
  }
  createFriendChat({ msg: d.m, friendId: d.id, type: CHAT_OPPONENT });
});
inRoute("FRIEND_REQUEST", () => getFriendReqests());
inRoute("FRIEND_RESPONSE", () => getFriends());
inRoute("SAVE_PROGRESS_RESPONSE", async (d) => {
  const registerModule = await import("../ducks/register");
  registerModule.setProgressKey(d.progressKey);
});

/* DAILY */
inRoute("DAILY_UPDATE", (d) => {
  getGame(d.gameId);
});
inRoute("CMOVE", (d) => {
  const cMoveData = {
    gameId: d.id,
    move: d.moveResult.move,
    possibleMoves: d.moveResult.dests,
    fen: d.moveResult.fen,
    lastMoveTime: d.lastMoveTime,
    san: d.moveResult.move.san,
  };
  import("../ducks/daily").then((daily) => daily.setWSNewMove(cMoveData));
  if (d.moveResult.gameResult != null) {
    getGame(d.id);
  }
});
inRoute("DAILY_CHAT", (d) =>
  import("../ducks/daily").then((daily) =>
    daily.setChat({ gameId: d.gameId, chatMessage: d.chatMessage })
  )
);

inRoute("ADD_DAILY_INVITE", (d) =>
  import("../ducks/daily").then((daily) => {
    daily.addDailyInvite(d.invite);
  })
);

inRoute("REMOVE_DAILY_INVITE", (d) =>
  import("../ducks/daily").then((daily) => daily.removeDailyInvite(d.id))
);

// inRoute("ADD_DAILY_GAME", (d) => getGame(d.gameId));

inRoute("DAILY_REMATCH", (d) => {
  // console.log("d", d);
  import("../ducks/daily").then((daily) => daily.setRematch(d.challenge));
});

/* NOTIFICATIONS */
inRoute("ADD_GAME_STARTED_NOTIFICATION", async (d) => {
  const notificationModule = await import("../ducks/notifications");

  const gameId = d.notification.entityId;
  await getGame(gameId);

  notificationModule.addNotification(d.notification);
});

inRoute("CHALLENGE_DAILY_GAME_STARTED", async (d) => {
  const challenge: AcceptedPrivateChallenge = d.challenge;
  const { oldGameId, slug, gameId, id } = challenge;
  removeChallenge(id);
  await getGame(gameId);

  const currentPath = Router.asPath;
  const onWaitingPage = currentPath == `/${slug}`;
  const onOldGamePage = oldGameId && currentPath == `/daily/game/${oldGameId}`;

  if (onWaitingPage || onOldGamePage) {
    Router.push("/daily/game/" + gameId);
  } else {
    const notificationModule = await import("../ducks/notifications");
    notificationModule.addNotification(d.notification);
  }
});

inRoute("ADD_NOTIFICATION", async (d) => {
  const [notificationModule] = await Promise.all([
    import("../ducks/notifications"),
  ]);
  notificationModule.notificationReceived(d.notification);
});
