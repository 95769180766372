import { AvatarTypes } from "@chesshotel/constants";
import { useEffect, useState } from "react";
import { BASE_URL } from "../services/api";

export default function Avatar({
  fbId,
  avatarId,
  avatarType,
  id,
  avatarUpdateCount = null,
  className,
  children = null,
}) {
  const [loaded, setLoaded] = useState(false);
  let src = "";
  let extraStyles = "";

  useEffect(() => {
    if (avatarType === AvatarTypes.DEFAULT && avatarId) {
      setLoaded(true);
    }
  }, [avatarType]);

  if (avatarType === AvatarTypes.FACEBOOK) {
    src = `${BASE_URL}users/facebook/${fbId}/fbavatar`;
  } else if (avatarType === AvatarTypes.DEFAULT && avatarId) {
    src = `/static/avatars/${avatarId}.svg`;
  } else if (avatarType === AvatarTypes.UPLOAD && id) {
    src = `${BASE_URL}users/${id}/avatar?v=${avatarUpdateCount}`;
    extraStyles = "border border-gray-200 bg-white";
  }

  return (
    <div className={`relative rounded-full ${className} ${extraStyles}`}>
      <img
        key={avatarType}
        className={`inline-block w-full h-full rounded-full`}
        src={src}
        alt="avatar"
        onLoad={() => {
          setLoaded(true);
        }}
        onError={() => {
          setLoaded(false);
        }}
        style={{ display: loaded ? "block" : "none" }}
      />
      <span
        className={`bg-gray-400 rounded-full ${className}`}
        style={{ display: loaded ? "none" : "block" }}
      >
        <svg
          className={className}
          color="#fff"
          width="100%"
          height="100%"
          viewBox="0 0 128 128"
          role="img"
          aria-label=" avatar"
        >
          <path
            fill="currentColor"
            d="M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z"
          ></path>
          <path
            fill="currentColor"
            d="M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24"
          ></path>
        </svg>
      </span>
      {children}
    </div>
  );
}
