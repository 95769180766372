import { createReduxModule } from "hooks-for-redux";
import { PrivateChallenge } from "@chesshotel/models";

interface ChallengePlayer {
  id: number;
  elo: number;
  avatarId: number;
  avatarType: number;
  fbId: number;
}

interface FullPrivateChallenge extends PrivateChallenge {
  opponent: ChallengePlayer;
  user: ChallengePlayer;
}

interface ChallengesState {
  challenges: FullPrivateChallenge[];
  sentChallenges: PrivateChallenge[];
  challengesMenuOpen: boolean;
}

const initialState: ChallengesState = {
  challenges: [],
  sentChallenges: [],
  challengesMenuOpen: false,
};

export const [
  useChallenges,
  {
    addChallenge,
    updateSentChallenge,
    setChallenges,
    removeChallenge,
    removeChallengeByUsername,
    declineChallenge,
    doAddSentChallenge,
    setChallengesMenuOpen,
    resetChallenges,
  },
  challengesStore,
] = createReduxModule("challenges", initialState, {
  addChallenge: (state, challenge) => ({
    ...state,
    challenges: [...state.challenges, challenge],
    challengesMenuOpen: true,
  }),
  removeChallenge: (state, id) => ({
    ...state,
    challenges: state.challenges.filter((challenge) => challenge.id !== id),
  }),
  removeChallengeByUsername: (state, username) => ({
    ...state,
    challenges: state.challenges.filter(
      (challenge) => challenge.username !== username
    ),
  }),
  declineChallenge: (state, id) => ({
    ...state,
    sentChallenges: state.sentChallenges.filter(
      (challenge) => challenge.id !== id
    ),
  }),
  doAddSentChallenge: (state, challenge) => {
    return {
      ...state,
      sentChallenges: [...state.sentChallenges, challenge],
    };
  },
  updateSentChallenge: (state, challenge) => ({
    ...state,
    sentChallenges: state.sentChallenges.map((chall) => {
      if (chall.id == challenge.id) {
        return challenge;
      } else {
        return chall;
      }
    }),
  }),
  setChallenges: (state, { received, sent }) => ({
    ...state,
    challenges: received,
    sentChallenges: sent,
  }),
  setChallengesMenuOpen: (state, open) => ({
    ...state,
    challengesMenuOpen: open,
  }),
  resetChallenges: () => initialState,
});

export function addSentChallenge(challenge) {
  const sentChallenges = challengesStore.getState().sentChallenges;

  const hasSame = !!sentChallenges.find((chall) => {
    const { id, created, ...rest } = chall;
    return JSON.stringify(rest) == JSON.stringify(challenge);
  });

  if (!hasSame) {
    return doAddSentChallenge(challenge);
  }
}
