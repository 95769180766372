import { createReduxModule } from "hooks-for-redux";
import { USE_PROD } from "../services/api";

const host = process.env.HOST;

export const PORT1 = 9090;
export const PORT2 = 9091;
export const PORT3 = 9092;

export let WEBSOCKET_URL1 = USE_PROD
  ? "wss://" + host + "/ws1/"
  : "ws://" + host + ":" + PORT1;
export let WEBSOCKET_URL2 = USE_PROD
  ? "wss://" + host + "/ws2/"
  : "ws://" + host + ":" + PORT2;
export let WEBSOCKET_URL3 = USE_PROD
  ? "wss://" + host + "/ws3/"
  : "ws://" + host + ":" + PORT3;

const initialState = {
  ws: null,
  websocketUrl: null,
  websocketUrls: {
    1: WEBSOCKET_URL1,
    2: WEBSOCKET_URL2,
    3: WEBSOCKET_URL3,
  },
  reconnectStart: null,
  reconnectTime: null,
  pingTime: 0,
  pingReceived: true,
  pingStart: Date.now(),
  otherLoginShow: false,
};

export const [
  useWsConnection,
  {
    setWebsocketUrl,
    doStartReconnectTimer,
    updateReconnectTimer,
    endReconnectTimer,
    setOtherLoginShow,
  },
  wsConnectionStore,
] = createReduxModule("wsConnection", initialState, {
  setWebsocketUrl: (state, ws) => ({
    ...state,
    ws,
    websocketUrl: state.websocketUrls[ws],
  }),
  doStartReconnectTimer: (state) => ({
    ...state,
    reconnectStart: Date.now(),
  }),
  updateReconnectTimer: (state) => ({
    ...state,
    reconnectTime: Date.now(),
  }),
  endReconnectTimer: (state) => ({
    ...state,
    reconnectStart: null,
    reconnectTime: null,
  }),
  setOtherLoginShow: (state, otherLoginShow) => ({
    ...state,
    otherLoginShow,
  }),
});
